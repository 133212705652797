import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 2rem;
  gap: 2rem;
  & > p {
    text-align: center;
    font-size: 1.8rem;
    & > span {
      font-family: "Montserrat Medium", sans-serif;
      color: ${({ theme }) => theme.colors.primary.default};
    }
  }
  & > button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    padding: 1rem 2rem;
    border-radius: 0.5rem;
    border: 1px solid ${({ theme }) => theme.colors.primary.default};
    color: ${({ theme }) => theme.colors.primary.default};
    background-color: ${({ theme }) => theme.colors.light.default};
  }
  @media (${({ theme }) => theme.medias.tablet}) {
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 0 2rem;
  padding-bottom: 2rem;
  max-width: 100rem;

  & > h4 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 1rem;
    font-size: 1.8rem;
    text-align: center;
    background-color: ${({ theme }) => theme.colors.light[200]};
    padding: 1rem;
    border-radius: 0.5rem;
    margin-bottom: 2rem;

    & > svg {
      min-width: 3rem;
    }
  }
`;

export const BorderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.primary.default};
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 2rem;

  & > h3 {
    font-family: "Montserrat Medium", sans-serif;

    padding: 1rem 0;
    font-size: 1.8rem;
    text-align: center;
  }

  & > p {
    padding: 1rem 0;
    font-size: 1.6rem;
    text-align: center;
  }

  & > ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    gap: 1rem;
    padding: 1rem;

    & > li {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      gap: 1rem;

      padding: 1rem 0;

      & > label {
        font-size: 1.6rem;
        font-family: "Montserrat Medium", sans-serif;
      }

      & > span {
        font-size: 1.4rem;
      }
    }
  }
`;

export const QRCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  max-width: 40rem;
  padding: 3rem;
`;
