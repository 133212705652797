import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 60rem;
  margin: 0 auto;
  gap: 3rem;
  padding: 2rem;
  & > h1 {
    width: 100%;
    text-align: center;
    font-size: 2.6rem;
  }
  & > h3 {
    width: 100%;
    text-align: center;
    & > span {
      font-family: "Merriweather Regular", serif;
      color: ${({ theme }) => theme.colors.primary.default};
    }
  }
  & > p {
    width: 100%;
    text-align: center;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 2rem;
`;

export const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 3rem;
  & > img {
    width: 100%;
    max-width: 30rem;
    min-width: 10rem;
    object-fit: contain;
  }
`;
