import React from "react";
const { default: Loading } = require("components/Loading");
const { useGetEventById } = require("services/events");

const UpdateWrapper = ({ eventId, children }) => {
  const { data: event, isLoading } = useGetEventById(eventId);

  if (isLoading) {
    return <Loading />;
  }
  return <>{React.cloneElement(children, { event: event })}</>;
};

export default UpdateWrapper;
