import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  position: relative;
  @media (${({ theme }) => theme.medias.tablet}) {
    flex-direction: row;
  }
`;

export const PlayerContainer = styled.div`
  width: 100%;
  display: flex;
  background-color: ${({ theme }) => theme.colors.dark[800]};
  @media (${({ theme }) => theme.medias.tablet}) {
    max-height: 100%;
    height: 100%;
    min-height: 100%;
    width: 60%;
  }
`;

export const PlaylistContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2rem;
  overflow-y: auto;
  padding-bottom: 8rem;
  @media (${({ theme }) => theme.medias.tablet}) {
    width: 40%;
    height: 100%;
  }
`;
