import { createContext, useCallback, useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { messages } from "i18n/messages";

const GlobalContext = createContext({
  user: null,
  authUser: null,
  language: null,
  userHandler: () => {},
  authUserHandler: () => {},
  changeLanguage: () => {},
});

const navigatorLanguage = () => {
  if (typeof window !== "undefined") {
    const language = window.navigator.language;
    if (language.includes("pt")) {
      return "pt-BR";
    } else if (language.includes("en")) {
      return "en-US";
    } else {
      return "en-US";
    }
  }
  return "en-US";
};

const getStorageValue = (key, defaultValue) => {
  const saved = localStorage.getItem(key);
  // check if is obj or array
  if (saved && (saved.includes("{") || saved.includes("["))) {
    const parseSaved = JSON.parse(saved);
    return parseSaved;
  }
  return saved || defaultValue;
};

export const GlobalContextProvider = (props) => {
  const [user, setUser] = useState();
  const [authUser, setAuthUser] = useState();
  const [language, setLanguage] = useState(
    getStorageValue("language", navigatorLanguage())
  );

  const [userMarket, setUserMarket] = useState("usd");

  const userHandler = useCallback((user) => {
    setUser(user);
  }, []);

  const authUserHandler = useCallback((user) => {
    setAuthUser(user);
  }, []);

  const changeLanguage = useCallback((newLanguage) => {
    setLanguage(newLanguage);
    localStorage.setItem("language", newLanguage);
  }, []);

  useEffect(() => {
    const getMarket = async () => {
      try {
        const response = await fetch("https://get.geojs.io/v1/ip/geo.json");
        const data = await response.json();
        const { continent_code, country_code } = data;

        if (country_code === "BR") {
          setUserMarket("brl");
        } else if (continent_code === "EU") {
          setUserMarket("eur");
        } else {
          setUserMarket("usd");
        }
      } catch (error) {
        console.log("error getting user location", error);
      }
    };
    getMarket();
  }, []);
  // states
  return (
    <GlobalContext.Provider
      value={{
        user,
        userHandler,
        authUser,
        authUserHandler,
        language,
        changeLanguage,
        userMarket,
      }}
    >
      <IntlProvider
        messages={messages[language]}
        locale={language}
        defaultLocale="en-US"
      >
        {props.children}
      </IntlProvider>
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
