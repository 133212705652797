import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore/lite";
import { getStorage } from "firebase/storage";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const firebaseApp = initializeApp(config);
const auth = getAuth(firebaseApp);
// connectAuthEmulator(auth, "http://localhost:9099");
const firestore = getFirestore(firebaseApp);
// connectFirestoreEmulator(firestore, "localhost", 8080);
const analytics = getAnalytics(firebaseApp);
const storage = getStorage(firebaseApp);
// connectStorageEmulator(storage, "localhost", 9199);

const firebaseConfig = {
  auth,
  analytics,
  firestore,
  storage,
};

export default firebaseConfig;
