import Button from "components/Button";
import Loading from "components/Loading";
import QRCode from "components/QRCode";
import {
  StyledButtonArea,
  StyledContent,
  StyledHeader,
  StyledLayout,
  StyledMessage,
} from "libs/styled-components/styles";
import StepMeter from "modules/events/components/WizardForm/components/StepMeter";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetEventById } from "services/events";
import { BorderContainer, Container, QRCodeContainer } from "./styles";
import { saveLog } from "services/logs";
import GlobalContext from "context";

const PaymentSuccess = () => {
  const { user } = useContext(GlobalContext);
  // eslint-disable-next-line no-unused-vars
  const [session, setSession] = useState({});
  const location = useLocation();
  const sessionId = location.search.replace("?session_id=", "");
  const params = useParams();
  const { data: event, isLoading } = useGetEventById(params.id);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchSession() {
      try {
        const res = await fetch("/checkout-session?sessionId=" + sessionId);
        setSession(res);
      } catch (error) {
        console.log("fetchSession", error);
      }
    }
    fetchSession();
  }, [sessionId]);

  const onFinish = () => {
    navigate(`/events/${event.id}`);
  };

  const qrCodeLink = useMemo(() => {
    return `https://evento.capsuladotempo.com/?event=${event?.code}`;
  }, [event]);

  const intl = useIntl();

  const onOpenLink = useCallback(() => {
    saveLog({
      action: "open-link",
      event: event?.id,
      user: user?.id,
      data: {
        component: "PaymentSuccess",
        recordLink: qrCodeLink,
        eventTitle: event?.title,
        eventDate: event?.date,
        eventCode: event?.code,
        eventStatus: event?.status,
        userEmail: user?.email,
        userName: user?.name,
        userOwner: event?.userOwner,
        userOwnerEmail: event?.userOwnerEmail,
        userOwnerName: event?.userOwnerName,
      },
    });
  }, [event, qrCodeLink, user]);

  const onShare = useCallback(() => {
    const text = intl.formatMessage({ id: "message.shareLink" });
    if (navigator.share) {
      navigator
        .share({
          title: event?.title,
          url: qrCodeLink,
          text: text,
        })
        .then(() => {
          saveLog({
            action: "share-event",
            event: event?.id,
            user: user?.id,
            data: {
              component: "PaymentSuccess",
              recordLink: qrCodeLink,
              eventTitle: event?.title,
              eventDate: event?.date,
              eventCode: event?.code,
              eventStatus: event?.status,
              userEmail: user?.email,
              userName: user?.name,
              userOwner: event?.userOwner,
              userOwnerEmail: event?.userOwnerEmail,
              userOwnerName: event?.userOwnerName,
            },
          });
          console.log("Thanks for sharing!");
        })
        .catch(console.error);
    } else {
      console.log("no-navigator");
    }
  }, [event, qrCodeLink, intl, user]);

  if (isLoading) {
    return <Loading />;
  }

  if (!event) {
    return (
      <StyledMessage>
        <h3>
          <FormattedMessage id="event.notFound" />
        </h3>
      </StyledMessage>
    );
  }

  return (
    <StyledLayout>
      <StyledHeader>
        <h1>
          <FormattedMessage id="payment.success" />
        </h1>
      </StyledHeader>
      <StyledContent>
        <Container>
          <StepMeter step={4} />
          <BorderContainer>
            <h3>
              <FormattedMessage id="title.done" />
            </h3>
            <p>
              <FormattedMessage id="message.done" />
            </p>
            <ul>
              <li>
                <label>
                  <FormattedMessage id="label.qrCodeLink" />
                </label>
                <span>
                  <FormattedMessage id="description.qrCodeLink" />
                </span>
                <QRCodeContainer>
                  <QRCode code={event?.code} size="800" logoSize="150" />
                </QRCodeContainer>
              </li>
              <li>
                <label>
                  <FormattedMessage id="label.recorderLink" />
                </label>
                <span>
                  <FormattedMessage id="description.recorderLink" />
                </span>
                <a
                  href={qrCodeLink}
                  onClick={onOpenLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  {qrCodeLink}
                </a>
              </li>
              {navigator.share && (
                <li>
                  <label>
                    <FormattedMessage id="label.shareLink" />
                  </label>
                  <span>
                    <FormattedMessage id="description.shareLink" />
                  </span>
                  <Button type="button" color="secondary" onClick={onShare}>
                    <FormattedMessage id="button.share" />
                  </Button>
                </li>
              )}
            </ul>
          </BorderContainer>
          <StyledButtonArea>
            <div />
            <Button type="button" color="primary" onClick={onFinish}>
              <FormattedMessage id="button.finish" />
            </Button>
          </StyledButtonArea>
        </Container>
      </StyledContent>
    </StyledLayout>
  );
};
export default PaymentSuccess;
