import { StyledFullLayout } from "libs/styled-components/styles";
import CancelMessage from "modules/giftCard/components/CancelMessage";

const Cancel = () => {
  return (
    <StyledFullLayout>
      <CancelMessage />
    </StyledFullLayout>
  );
};

export default Cancel;
