import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  font-size: 2rem;

  & > div {
    position: relative;
  }
  & > p {
    font-size: 2rem;
  }
  & input {
    width: 100%;
    font-size: 2rem;
    padding: 1rem;
    border-radius: 0.5rem;
    border: 1px solid ${({ theme }) => theme.colors.dark[300]};
  }
  &.error {
    color: ${(p) => p.theme.colors.danger[600]};
  }
  & svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
    pointer-events: none;
    user-select: none;
  }

  & .react-datepicker {
    font-size: 1.6rem;
    width: 30rem;

    & .react-datepicker__current-month {
      padding: 2px;
      font-size: 2rem;
    }
    & .react-datepicker__day-names {
      display: flex;
      justify-content: space-between;
    }
    & .react-datepicker__day-name {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3rem;
      height: 3rem;
    }
    & .react-datepicker__week {
      display: flex;
      justify-content: space-between;
    }
    & .react-datepicker__day {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3rem;
      height: 3rem;
    }
    & .react-datepicker__month-container {
      width: 100%;
    }
  }
`;
