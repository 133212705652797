import { ImageContainer } from "modules/events/components/List/styles";
import { BorderContainer, Container } from "./styles";
import Banner from "assets/images/banner-placeholder.png";
import { FormattedMessage, useIntl } from "react-intl";
import { Share } from "assets/svg";
import { saveLog } from "services/logs";
import { useCallback, useContext, useMemo } from "react";
import GlobalContext from "context";
import { formatDate } from "utils";

const EventNotHeld = ({ event }) => {
  const { user } = useContext(GlobalContext);

  const recordLink = useMemo(() => {
    return `https://evento.capsuladotempo.com/?event=${event.code}`;
  }, [event]);

  const onOpenLink = useCallback(() => {
    saveLog({
      action: "open-link",
      event: event?.id,
      user: user?.id,
      data: {
        component: "Videos/EventNotHeld",
        recordLink: recordLink,
        eventTitle: event?.title,
        eventDate: event?.date,
        eventCode: event?.code,
        eventStatus: event?.status,
        userEmail: user?.email,
        userName: user?.name,
        userOwner: event?.userOwner,
        userOwnerEmail: event?.userOwnerEmail,
        userOwnerName: event?.userOwnerName,
      },
    });
  }, [event, recordLink, user]);

  const intl = useIntl();

  const onShare = useCallback(() => {
    const text = intl.formatMessage({ id: "message.shareLink" });
    if (navigator.share) {
      navigator
        .share({
          title: event?.title,
          url: recordLink,
          text: text,
        })
        .then(() => {
          console.log("Thanks for sharing!");
          saveLog({
            action: "share-event",
            event: event?.id,
            user: user?.id,
            data: {
              component: "Videos/EventNotHeld",
              recordLink: recordLink,
              eventTitle: event?.title,
              eventDate: event?.date,
              eventCode: event?.code,
              eventStatus: event?.status,
              userEmail: user?.email,
              userName: user?.name,
              userOwner: event?.userOwner,
              userOwnerEmail: event?.userOwnerEmail,
              userOwnerName: event?.userOwnerName,
            },
          });
        })
        .catch(console.error);
    } else {
      console.log("no-navigator");
    }
  }, [event, recordLink, intl, user]);
  return (
    <Container>
      <h1>{event.title}</h1>
      <h3>{formatDate(event.market, event.date)}</h3>
      <ImageContainer>
        <img src={event.banner ? event.banner : Banner} alt={event.title} />
      </ImageContainer>
      <p></p>
      <BorderContainer>
        <h2>
          <FormattedMessage id="message.event.notHeld" />
        </h2>
        <p>
          <FormattedMessage id="message.event.notHeld.description" />
        </p>
        <div>
          <a
            href={recordLink}
            onClick={onOpenLink}
            target="_blank"
            rel="noreferrer"
          >
            <FormattedMessage id="event.link" />
            <Share
              height="26px"
              width="26px"
              outline="#f76c6f"
              color="transparent"
            />
          </a>
          {navigator.share && (
            <button type="button" onClick={onShare}>
              <FormattedMessage id="button.share" />
              <Share
                height="26px"
                width="26px"
                outline="#fff"
                color="transparent"
              />
            </button>
          )}
        </div>
      </BorderContainer>
    </Container>
  );
};

export default EventNotHeld;
