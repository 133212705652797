import styled from "styled-components";

export const Container = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  @media (${({ theme }) => theme.medias.tablet}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 4rem;
  }
`;

export const PriceTag = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-size: 2rem;
  font-family: "Merriweather Regular", serif;
`;

export const SummaryContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  border: 1px solid ${(props) => props.theme.colors.primary.default};
  padding: 2rem;
  border-radius: 1rem;
  & > h2 {
    width: 100%;
    text-align: left;
    font-family: "Merriweather Regular", serif;
    & > span {
      color: ${(props) => props.theme.colors.primary.default};
    }
  }
  & > p {
    padding: 1rem 0;
    width: 100%;
    text-align: left;
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding: 3rem 0;
  & > h3 {
    width: 100%;
    font-size: 2.2rem;
    text-align: left;
  }
  & > p {
    text-align: center;
    font-size: 1.4rem;
  }
  @media (${({ theme }) => theme.medias.tablet}) {
    padding: 0rem;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  & > button {
    width: 100%;
    padding: 1.4rem 3rem;
    background-color: ${(props) => props.theme.colors.primary.default};
    color: ${(props) => props.theme.colors.light.default};
    font-size: 1.6rem;
    border: none;
    border-radius: 2.4rem;
  }
`;
