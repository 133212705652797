import { FlagBR, FlagUS } from "assets/svg";
import GlobalContext from "context";
import { useContext, useState } from "react";
import { Container, SelectLanguageContainer } from "./styles";
import SimpleModal from "components/SimpleModal";
import { FormattedMessage } from "react-intl";

const LanguageSelector = () => {
  const { language, changeLanguage } = useContext(GlobalContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  const onOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleLanguageChange = (newLanguage) => {
    changeLanguage(newLanguage);
    onCloseModal();
  };

  return (
    <>
      <Container onClick={onOpenModal}>
        {language === "pt-BR" && <FlagBR width="30" />}
        {language === "en-US" && <FlagUS width="30" />}
      </Container>
      <SimpleModal
        isOpen={isModalOpen}
        handleClose={onCloseModal}
        title={
          <FormattedMessage
            id="title.selectLanguage"
            defaultMessage="Selecione o idioma"
          />
        }
      >
        <SelectLanguageContainer>
          <label onClick={() => handleLanguageChange("en-US")}>
            English
            <FlagUS width="40" />
          </label>
          <label onClick={() => handleLanguageChange("pt-BR")}>
            Português
            <FlagBR width="40" />
          </label>
        </SelectLanguageContainer>
      </SimpleModal>
    </>
  );
};
export default LanguageSelector;
