import { useForm } from "react-hook-form";
import { Container } from "./styles";
import FirebaseAuthService from "libs/firebase/FirebaseAuthService";
import { useNavigate } from "react-router-dom";
import InputText from "components/InputText";
import { useState } from "react";
import { StyledForm, StyledLine } from "libs/styled-components/styles";
import LoginButton from "components/LoginButton";
import { FormattedMessage } from "react-intl";
const Form = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      await FirebaseAuthService.resetPassword(data.email);
      navigate("/");
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Container>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <StyledLine>
          <InputText
            label="Email"
            field="email"
            type="email"
            errors={errors}
            required={true}
            register={register}
          />
        </StyledLine>
        {error && <p>{error}</p>}
        <LoginButton type="submit">
          <FormattedMessage id="button.resetPassword" />
        </LoginButton>
      </StyledForm>
    </Container>
  );
};

export default Form;
