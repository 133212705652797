import { Label } from "./styles";
import { FormattedMessage } from "react-intl";
const InputTextarea = ({
  label,
  field,
  errors,
  register,
  required,
  validate,
  pattern,
  defaultValue,
  placeholder,
  type,
  rows,
  cols,
}) => {
  return (
    <Label className={errors[field] && "error"}>
      {label}
      <textarea
        type={type ? type : "text"}
        defaultValue={defaultValue}
        placeholder={placeholder}
        {...register(field, { required, validate, pattern })}
        rows={rows}
        cols={cols}
      />
      {errors[field] && (
        <span>
          {errors[field].message ? (
            errors[field].message
          ) : (
            <FormattedMessage id="error.required" />
          )}
        </span>
      )}
    </Label>
  );
};

export default InputTextarea;
