import styled from "styled-components";

export const Container = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 60rem;
  margin: 0 auto;
  & > p {
    width: 100%;
    text-align: center;
    padding: 3rem 2rem;
    & > span {
      color: ${({ theme }) => theme.colors.primary.default};
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 2rem;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 2rem;
  border: 1px solid ${({ theme }) => theme.colors.primary.default};
  border-radius: 1rem;
  text-align: center;
  font-family: "Merriweather Regular";
  & > h2 {
    width: 100%;
    & > span {
      color: ${({ theme }) => theme.colors.primary.default};
    }
  }
`;

export const HowItWorksContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 100%;
  padding: 4rem 2rem;
  & > p {
    font-size: 1.6rem;
    width: 100%;
    text-align: center;
  }
  & > span {
    font-size: 1.8rem;
    width: 100%;
    text-align: center;
    font-family: "Merriweather Bold";
    & > span {
      color: ${({ theme }) => theme.colors.primary.default};
    }
  }
`;

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 2rem;
  & > h2 {
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
  }
  & > ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2rem;
    & > li {
      display: flex;
      flex-direction: row;
      font-size: 1.6rem;
      width: 100%;
      text-align: left;
      gap: 1.5rem;
      padding-bottom: 1.6rem;
      border-bottom: 1px solid ${({ theme }) => theme.colors.dark[100]};
      & > span {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${({ theme }) => theme.colors.primary.default};
        color: ${({ theme }) => theme.colors.light.default};
        width: 2.5rem;
        height: 2.5rem;
        min-height: 2.5rem;
        min-width: 2.5rem;
        border-radius: 2rem;
      }
      & > p {
        width: 100%;
        & > img {
          margin: 1rem 0;
          width: 100%;
          border-radius: 1rem;
          box-shadow: 0 0 1rem rgba(108, 108, 108, 0.5);
        }
      }
    }
  }
`;
