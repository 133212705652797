import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const Content = styled.div`
  width: 24em;
`;
