import LoginButton from "components/LoginButton";
import GlobalContext from "context";
import FirebaseAuthService from "libs/firebase/FirebaseAuthService";
import {
  StyledContent,
  StyledHeader,
  StyledLayout,
} from "libs/styled-components/styles";
import { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { Content } from "./styles";

const VerifyEmail = () => {
  const { authUser } = useContext(GlobalContext);

  const handleResendEmail = async () => {
    await FirebaseAuthService.sendEmailVerification(authUser);
  };
  return (
    <StyledLayout>
      <StyledHeader>
        <h1>
          <FormattedMessage id="title.checkYourEmail" />
        </h1>
      </StyledHeader>
      <StyledContent>
        <Content>
          <h2>
            <FormattedMessage id="description.checkYourEmail" />
          </h2>

          <p>
            <FormattedMessage id="title.dontReceiveEmail" />
            <br />
            <span>
              <FormattedMessage id="description.dontReceiveEmail" />
            </span>
          </p>
          <LoginButton model="new" onClick={handleResendEmail}>
            <FormattedMessage id="button.resendEmail" />
          </LoginButton>
          <LoginButton onClick={() => window.location.reload(false)}>
            <FormattedMessage id="button.alreadyConfirmed" />
          </LoginButton>
        </Content>
      </StyledContent>
    </StyledLayout>
  );
};
export default VerifyEmail;
