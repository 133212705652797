import { forwardRef } from "react";
import { ContainerToPreview, Container } from "./styles";

const ComponentToPreviewLandscape = forwardRef((props, ref) => {
  return (
    <Container>
      <ContainerToPreview ref={ref}>{props.children}</ContainerToPreview>;
    </Container>
  );
});

export default ComponentToPreviewLandscape;
