import { forwardRef } from "react";
import {
  BottomLeft,
  BottomRight,
  Container,
  ContainerToPrint,
  HorizontalDivider,
  TopLeft,
  TopRight,
  VerticalDividerBottom,
  VerticalDividerTop,
} from "./styles";

const ComponentToPrintLandscape = forwardRef((props, ref) => {
  return (
    <Container>
      <ContainerToPrint ref={ref}>
        <TopLeft>{props.children}</TopLeft>
        <VerticalDividerTop />
        <TopRight>{props.children}</TopRight>
        <HorizontalDivider />
        <BottomLeft>{props.children}</BottomLeft>
        <VerticalDividerBottom />
        <BottomRight>{props.children}</BottomRight>
      </ContainerToPrint>
    </Container>
  );
});

export default ComponentToPrintLandscape;
