import Header from "components/Header";

import styled from "styled-components";

const OpenRouter = ({ children }) => {
  return (
    <>
      <Header />
      <Layout>{children}</Layout>
    </>
  );
};

export default OpenRouter;

const Layout = styled.div`
  overflow-y: auto;
  height: -webkit-fill-available;
  height: 100%;
`;
