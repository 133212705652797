import {
  StyledContent,
  StyledHeader,
  StyledLayout,
} from "libs/styled-components/styles";
import WizardForm from "modules/events/components/WizardForm";
import { FormattedMessage } from "react-intl";

const CreateEvent = () => {
  return (
    <StyledLayout>
      <StyledHeader>
        <h1>
          <FormattedMessage id="event.new" />
        </h1>
      </StyledHeader>
      <StyledContent>
        <WizardForm />
      </StyledContent>
    </StyledLayout>
  );
};

export default CreateEvent;
