import { StyledFullLayout } from "libs/styled-components/styles";
import Onboarding from "modules/giftCard/components/Onboarding";

const Main = () => {
  return (
    <StyledFullLayout>
      <Onboarding />
    </StyledFullLayout>
  );
};

export default Main;
