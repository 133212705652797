import { useNavigate } from "react-router-dom";
import {
  Container,
  DescriptionContainer,
  ImageContainer,
  ListItem,
} from "./styles";
import Banner from "assets/images/banner-placeholder.png";
import { formatDate } from "utils";

const EventList = ({ events }) => {
  const navigate = useNavigate();

  const onEventClick = (id) => {
    navigate(`/events/${id}`);
  };

  const orderedEvents = events.sort((a, b) => {
    return new Date(b.date) - new Date(a.date);
  });

  return (
    <Container>
      {orderedEvents.map((item) => {
        return (
          <ListItem key={item.id} onClick={() => onEventClick(item.id)}>
            <ImageContainer>
              {item.banner ? (
                <img src={item.banner} alt="banner" />
              ) : (
                <img src={Banner} alt="banner" />
              )}
            </ImageContainer>
            <DescriptionContainer>
              <h2>{item.title}</h2>
              <p>{formatDate(item.market, item.date)}</p>
            </DescriptionContainer>
          </ListItem>
        );
      })}
    </Container>
  );
};

export default EventList;
