import { useForm } from "react-hook-form";
import { Container, EditContainer, MessageContainer } from "./styles";
import InputText from "components/InputText";
import Button from "components/Button";
import { useNavigate } from "react-router-dom";
import { useUpdateEvent } from "services/events";
import {
  StyledButtonArea,
  StyledForm,
  StyledLine,
} from "libs/styled-components/styles";
import BannerSection from "../BannerSection";
import MailListSection from "../MailListSection";
import Spinner from "components/Spinner";
import { FormattedMessage } from "react-intl";
import DateInput from "components/DateInput";
import { saveLog } from "services/logs";
import { useContext, useMemo } from "react";
import GlobalContext from "context";
import { convertToDate, dateToString, formatDate } from "utils";
import InputTextarea from "components/InputTextarea";

const EventForm = ({ event }) => {
  const { user } = useContext(GlobalContext);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm();
  const { mutate: update, isLoading } = useUpdateEvent();
  const navigate = useNavigate();

  const watchDate = watch("date");

  const minDate = useMemo(() => {
    let minBaseDate = null;
    if (watchDate) {
      minBaseDate = new Date(watchDate);
    } else {
      minBaseDate = convertToDate(event?.date);
    }
    minBaseDate.setMonth(minBaseDate.getMonth() + 1);

    return dateToString(minBaseDate);
  }, [watchDate, event]);

  const maxDate = useMemo(() => {
    let maxBaseDate = null;

    if (watchDate) {
      maxBaseDate = new Date(watchDate);
    } else {
      maxBaseDate = convertToDate(event?.date);
    }
    maxBaseDate.setFullYear(maxBaseDate.getFullYear() + 5);

    return dateToString(maxBaseDate);
  }, [watchDate, event]);

  const validateDate = (newDate) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const date = new Date(newDate);
    date.setHours(0, 0, 0, 0);

    if (date <= today) {
      return <FormattedMessage id="error.dateGreaterOrEqualToday" />;
    }
  };
  /**
   * newDate must be greater minDate
   * newDate must be less than maxDate
   **/
  const validateReleaseDate = (newDate) => {
    const date = new Date(newDate);
    date.setHours(0, 0, 0, 0);
    const min = convertToDate(minDate);
    const max = convertToDate(maxDate);

    if (date < min) {
      return <FormattedMessage id="error.dateGreaterOrEqualOneMonthLater" />;
    }
    if (date > max) {
      return <FormattedMessage id="error.dateLessOrEqualFiveYearsLater" />;
    }
  };

  const onSubmit = (data) => {
    //update
    if (event) {
      //convert date to String
      data.date = dateToString(data.date);
      //convert releaseDate to String
      data.releaseDate = dateToString(data.releaseDate);
      const newData = { ...event, ...data, id: event.id };
      update(newData, {
        onSuccess: (result) => {
          navigate(`../events/${result.id}`);
        },
      });
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  const onDelete = () => {
    saveLog({
      action: "delete-event",
      user: user?.id,
      event: event?.id,
      data: {
        userEmail: user?.email,
        userName: user?.name,
        eventName: event?.title,
      },
    });
    alert("Entre em contato com o suporte para remover.");
  };

  const isUserAdmin = user?.role === "admin";

  return (
    <Container>
      <EditContainer>
        {event && <BannerSection event={event} />}
        {event && <MailListSection event={event} />}
      </EditContainer>

      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <StyledLine>
          <InputText
            label={<FormattedMessage id="label.title" />}
            field="title"
            placeholder="Juliana & Patrick"
            errors={errors}
            required={true}
            register={register}
            defaultValue={event?.title}
          />
        </StyledLine>

        <StyledLine>
          <DateInput
            label={<FormattedMessage id="label.date" />}
            name="date"
            value={event?.date}
            control={control}
            validate={validateDate}
            errors={errors}
          />
        </StyledLine>

        <StyledLine>
          <DateInput
            label={<FormattedMessage id="label.releaseDate" />}
            name="releaseDate"
            value={event?.releaseDate}
            minDate={minDate}
            maxDate={maxDate}
            control={control}
            validate={validateReleaseDate}
            errors={errors}
          />
          <MessageContainer>
            <FormattedMessage
              id="message.releaseDate"
              values={{
                minDate: formatDate("BRL", minDate),
                maxDate: formatDate("BRL", maxDate),
              }}
            />
          </MessageContainer>
        </StyledLine>

        {isUserAdmin && (
          <StyledLine>
            <InputTextarea
              label="Customização"
              field="customization"
              errors={errors}
              register={register}
              defaultValue={event?.customization}
              rows={30}
              cols={120}
            />
          </StyledLine>
        )}

        <StyledButtonArea>
          {event && (
            <Button type="button" color="delete" onClick={onDelete}>
              <FormattedMessage id="button.delete" />
            </Button>
          )}

          <Button type="button" color="secondary" onClick={onCancel}>
            <FormattedMessage id="button.goBack" />
          </Button>
          <Button type="submit" color="primary">
            {isLoading ? <Spinner /> : <FormattedMessage id="button.update" />}
          </Button>
        </StyledButtonArea>
      </StyledForm>
    </Container>
  );
};

export default EventForm;
