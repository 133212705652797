import styled, { css } from "styled-components";

export const ButtonWrapper = styled.button`
  display: flex;
  align-self: stretch;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
  border: none;
  color: ${(p) => p.theme.colors.text.light};
  gap: 0.6rem;
  min-width: 10rem;
  height: 4rem;
  cursor: pointer;
  ${({ color, disabled }) => {
    if (disabled) {
      return css`
        background-color: ${(p) => p.theme.colors.dark[300]};
        color: ${(p) => p.theme.colors.text.light};
      `;
    } else if (color) {
      if (color === "primary") {
        return css`
          background-color: ${(p) => p.theme.colors.primary.default};
          border: 1px solid ${(p) => p.theme.colors.primary.default};
          color: ${(p) => p.theme.colors.text.light};
        `;
      }
      if (color === "secondary") {
        return css`
          background-color: ${(p) => p.theme.colors.light.default};
          border: 1px solid ${(p) => p.theme.colors.primary.default};
          color: ${(p) => p.theme.colors.primary.default};
        `;
      }
      if (color === "cancel") {
        return css`
          background-color: ${(p) => p.theme.colors.dark[300]};
          color: ${(p) => p.theme.colors.text.light};
        `;
      }
      if (color === "delete") {
        return css`
          background-color: ${(p) => p.theme.colors.danger[500]};
        `;
      }
      if (color === "success") {
        return css`
          background-color: ${(p) => p.theme.colors.success[900]};
        `;
      }
    } else {
      return css`
        background-color: ${(p) => p.theme.colors.primary.default};
      `;
    }
  }}
`;
