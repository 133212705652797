import { Play } from "assets/svg";
import { useCallback } from "react";
import { FormattedDateParts, FormattedMessage } from "react-intl";
import { Container, Info, Control, NowPlaying, DateItem } from "./styles";

const GroupItem = ({ video, onSelectVideo, isPlaying }) => {
  const onClickHandler = useCallback(() => {
    onSelectVideo(video);
  }, [video, onSelectVideo]);

  return (
    <Container>
      {isPlaying && (
        <NowPlaying>
          <FormattedMessage id="app.playing" />
        </NowPlaying>
      )}
      <Info>
        <label>
          {video.videoNumber ? (
            <>
              #<span>{video.videoNumber}</span>
            </>
          ) : (
            <span>
              <FormattedDateParts
                value={video.createdAt}
                year="2-digit"
                month="numeric"
                day="2-digit"
              >
                {(parts) => (
                  <DateItem>
                    <p>{parts[0].value}</p>
                    <p>{parts[2].value}</p>
                    <p>{parts[4].value}</p>
                  </DateItem>
                )}
              </FormattedDateParts>
            </span>
          )}
        </label>
        <img src={video.thumbnail} alt={video.title} />
      </Info>
      <Control onClick={onClickHandler}>
        <Play width="80" height="80" color="white" />
      </Control>
    </Container>
  );
};

export default GroupItem;
