import { useMemo } from "react";
import { BlockedGroup, Container, GroupInfo, GroupList } from "./styles";
import { FormattedMessage } from "react-intl";
import { Lock } from "assets/svg";
import { formatDate, getRemainingDays } from "utils";
import GroupItem from "../GroupItem";

const Group = ({ group, currentVideo, playVideo }) => {
  const isBlocked = useMemo(() => {
    const releaseDate = new Date(group.releaseDate);
    releaseDate.setHours(0, 0, 0, 0);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return releaseDate > today;
  }, [group]);

  if (isBlocked) {
    return (
      <BlockedGroup key={group.name}>
        <h3>
          <FormattedMessage id={`title.delivery.${group.name}`} />
        </h3>
        <Lock width="40" height="40" color="transparent" outline="#222" />
        <label>
          <FormattedMessage
            id="message.daysRemainingToRelease"
            values={{
              count: getRemainingDays(group.releaseDate),
              code: (text) => <span>{text}</span>,
            }}
          />
        </label>
        <label>
          <FormattedMessage id="label.videoMessages" />: {group.videos.length}
        </label>
      </BlockedGroup>
    );
  }
  return (
    <Container>
      <GroupInfo>
        <h3>
          <FormattedMessage id={`title.delivery.${group.name}`} />
        </h3>
        <label>
          <FormattedMessage id="label.releaseDate" />:
          <span>{formatDate("BRL", group.releaseDate)}</span>
        </label>
      </GroupInfo>
      <GroupList>
        {group.videos.map((video) => (
          <GroupItem
            key={video.videoId}
            video={video}
            isPlaying={currentVideo?.videoId === video.videoId}
            onSelectVideo={playVideo}
          />
        ))}
      </GroupList>
    </Container>
  );
};
export default Group;
