import FirebaseFirestoreService from "libs/firebase/FirebaseFirestoreService";
const COLLECTION = "affiliates";

export const getAffiliateByEmail = async (email) => {
  try {
    const response = await FirebaseFirestoreService.readDocuments({
      collection: COLLECTION,
      queries: [
        {
          field: "email",
          condition: "==",
          value: email,
        },
      ],
    });

    if (response.docs.length === 0) return null;
    return response.docs[0].data();
  } catch (error) {
    const errorMsg = `getAffiliateByEmail.readDocuments [${JSON.stringify(
      error
    )}]`;
    console.log(errorMsg);
  }
};
