import { ArrowLeft } from "assets/svg";
import Logo from "components/Logo";
import {
  Container,
  RightContainer,
  LogoutButton,
  LeftContainer,
} from "./styles";
import { useLocation, useNavigate } from "react-router-dom";
import FirebaseAuthService from "libs/firebase/FirebaseAuthService";
import { useContext, useMemo } from "react";
import GlobalContext from "context";
import LanguageSelector from "components/LanguageSelector";
import { FormattedMessage } from "react-intl";
import { useQueryClient } from "@tanstack/react-query";

const Header = () => {
  const { authUser } = useContext(GlobalContext);
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  const handleLogout = () => {
    queryClient.clear();
    FirebaseAuthService.logoutUser();
  };

  const showBackButton = useMemo(() => {
    if (location.pathname === "/") {
      return false;
    }
    if (location.pathname.includes("/open-event/watch")) {
      return true;
    }
    if (location.pathname.includes("/open-event/print")) {
      return true;
    }
    if (location.pathname.includes("/open-event")) {
      return false;
    }
    return true;
  }, [location.pathname]);

  const showLogoutButton = useMemo(() => {
    if (location.pathname.includes("/open-event")) {
      return false;
    }
    if (authUser) {
      return true;
    }
    return false;
  }, [location.pathname, authUser]);

  return (
    <Container>
      {showBackButton ? (
        <LeftContainer onClick={() => navigate(-1)}>
          <ArrowLeft
            width="32"
            height="32"
            color="transparent"
            outline="white"
          />
        </LeftContainer>
      ) : (
        <LeftContainer></LeftContainer>
      )}
      <div onClick={() => navigate("/")}>
        <Logo color="#ffffff" />
      </div>
      <RightContainer>
        <LanguageSelector />
        {showLogoutButton && (
          <LogoutButton type="button" onClick={handleLogout}>
            <FormattedMessage id="app.logout" />
          </LogoutButton>
        )}
      </RightContainer>
    </Container>
  );
};
export default Header;
