import Loading from "components/Loading";
import Spinner from "components/Spinner";
import GlobalContext from "context";
import FirebaseFirestoreRestService from "libs/firebase/FirebaseFirestoreRestService";
import { useCallback, useContext, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useGetPlans } from "services/plans";
import { formatValue } from "utils";
import { ButtonElement, Container } from "./styles";

const PayButton = ({ event }) => {
  const { user } = useContext(GlobalContext);
  const [isProcessing, setIsProcessing] = useState(false);
  const { data: plans, isLoading } = useGetPlans();

  const plan = useMemo(() => {
    // let planMarket;
    // if (event.market.toLowerCase() === "usd") {
    //   planMarket = "default_usd";
    // } else if (event.market.toLowerCase() === "eur") {
    //   planMarket = "default_eur";
    // } else {
    //   planMarket = "default_brl";
    // }
    return plans?.find((plan) => plan.type === "default_brl");
  }, [plans]);

  const onPayHandler = useCallback(
    async (e) => {
      e.preventDefault();

      setIsProcessing(true);
      const payment = {
        price: plan?.stripePrice,
        eventId: event.id,
        customerEmail: user.email,
        currency: "brl",
        product: "event",
      };

      const logData = {
        action: "click-payment",
        event: event?.id,
        user: user?.id,
        data: {
          payment: "paid-default",
          planPrice: plan?.stripePrice,
          planValue: plan?.value,
          planType: plan?.type,
          eventTitle: event?.title,
          eventDate: event?.date,
          eventCode: event?.code,
          eventStatus: event?.status,
          userEmail: user?.email,
          userName: user?.name,
          userOwner: event?.userOwner,
          userOwnerEmail: event?.userOwnerEmail,
          userOwnerName: event?.userOwnerName,
        },
      };

      try {
        const response = await FirebaseFirestoreRestService.createPayment({
          payment,
          logData,
        });
        window.location.href = response.url;
      } catch (error) {
        setIsProcessing(false);

        console.error("onPayHandler error", error);
      }
    },
    [event, user, plan]
  );

  return (
    <Container>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <h2>{plan.title}</h2>
          {/* {user.coupon ? <CouponButton event={event} /> : null} */}
          {/* TODO: Arrumar quando aplicar preços internacionais */}
          <h3>
            <FormattedMessage id="event.price" />:{" "}
            {formatValue("brl", plan.value)}
          </h3>
          <p>
            <FormattedMessage id="description.plan.paid" />
          </p>

          <ButtonElement
            type="button"
            onContextMenu={(e) => e.preventDefault()}
            onClick={onPayHandler}
            disabled={isProcessing}
          >
            {isProcessing ? (
              <Spinner />
            ) : (
              <FormattedMessage id="event.pay.now" />
            )}
          </ButtonElement>
        </div>
      )}
    </Container>
  );
};

export default PayButton;
