import Loading from "components/Loading";
import VideoWatcher from "components/VideoWatcher";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useGetVideos } from "services/videos";

const Watch = () => {
  const params = useParams();
  const { data: videos, isLoading } = useGetVideos(params.id);

  const playlist = useMemo(() => {
    const arrVideos = videos?.map((video, idx) => {
      let videoNumber = null;

      if (
        video.id === "Mtt1NKlx3oCJWeSRr3pu" ||
        video.id === "4GIVHYg7GExVaYp1lWfz" ||
        video.id === "Ij1YSFZ3sAlMBCdgjYYZ"
      ) {
        videoNumber = "EventoReal";
      }

      return {
        vimeoUrl: video.vimeoUrl,
        videoId: video.id,
        thumbnail: video.thumbnail,
        createdAt: video.createdAt,
        videoNumber: videoNumber,
      };
    });
    return [
      {
        name: "recent",
        releaseDate: "2018-05-12",
        videos: arrVideos,
      },
    ];
  }, [videos]);

  if (isLoading) {
    return <Loading />;
  }

  return <VideoWatcher videoList={playlist} />;
};

export default Watch;
