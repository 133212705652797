import { Edit } from "assets/svg";
import Button from "components/Button";
import Loading from "components/Loading";
import {
  StyledHeader,
  StyledLayout,
  StyledMessage,
} from "libs/styled-components/styles";
import EventSection from "modules/events/components/EventSection";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { useGetEventById } from "services/events";
import { Content } from "./styles";

const ViewEvent = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { data: event } = useGetEventById(params.id);
  const onEditEvent = () => {
    navigate(`/events/edit/${params.id}`);
  };
  const isEditable = useMemo(() => {
    const eventDate = new Date(event?.date);
    const today = new Date();
    return eventDate > today;
  }, [event]);

  if (!event) {
    return <Loading />;
  }
  if (!event) {
    return (
      <StyledMessage>
        <h3>
          <FormattedMessage id="event.notFound" />
        </h3>
      </StyledMessage>
    );
  }

  return (
    <StyledLayout>
      <StyledHeader>
        <h3>{event.title}</h3>

        {isEditable && (
          <Button color="primary" onClick={onEditEvent}>
            <Edit color="transparent" outline="white" width="24" height="24" />
            <FormattedMessage id="event.edit" />
          </Button>
        )}
      </StyledHeader>
      <Content>
        <EventSection event={event} />
      </Content>
    </StyledLayout>
  );
};
export default ViewEvent;
