import styled from "styled-components";

export const Container = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 2rem;
  padding-bottom: 10rem;
  gap: 2rem;
  & > h2 {
    font-size: 2rem;
    font-family: "Montserrat Medium", sans-serif;
  }
  & > h3 {
    font-size: 1.8rem;
    font-family: "Montserrat Regular", sans-serif;
  }
  & > p {
    font-size: 1.2rem;
    font-family: "Montserrat Regular", sans-serif;
  }
`;
