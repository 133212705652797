import styled from "styled-components";
export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2rem;
  height: 100%;
  @media (${({ theme }) => theme.medias.tablet}) {
    width: fit-content;
    align-self: start;
    justify-self: flex-start;
  }
  & > h3 {
    margin: auto 0;
  }
`;

export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: auto 0;
  padding: 5rem 2rem;
  gap: 1rem;
  background-color: ${({ theme }) => theme.colors.light.default};
  color: ${({ theme }) => theme.colors.text.dark};
  & > progress {
    width: 100%;
    height: 1rem;
    border-radius: 0.5rem;
    overflow: hidden;
    border: 1px solid ${({ theme }) => theme.colors.dark.default};
    background-color: ${({ theme }) => theme.colors.light.default};
    &::-webkit-progress-bar {
      background-color: ${({ theme }) => theme.colors.light.default};
    }
  }

  & > span {
    font-size: 2rem;
  }
`;

export const UploadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 20rem;
  margin: auto 0;
  gap: 2rem;
`;
