import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > h1 {
    padding: 0.2em 1em;
    text-align: center;
    font-size: 3.6em;
    font-family: "Hyldae Script";
    font-weight: 200;
    border-bottom: 1px solid ${(props) => props.theme.colors.dark[300]};
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 3em;
  & > div {
    & > h2 {
      max-width: 10em;
      padding: 0 1em;
      text-align: center;
      font-size: 2.4em;
      font-family: "Cormorant Light";
    }
  }
`;
export const QRCodeContainer = styled.div`
  width: 18em;
  padding: 3em;
`;

export const LogoContainer = styled.div`
  font-size: 0.5em;
`;
