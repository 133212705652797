import Checkout from "modules/giftCard/components/Checkout";

import { StyledLayout, StyledHeader } from "libs/styled-components/styles";
import { FormattedMessage } from "react-intl";

const CheckoutPage = () => {
  return (
    <StyledLayout>
      <StyledHeader>
        <h2>
          <FormattedMessage id="title.yourPurchase" />
        </h2>
      </StyledHeader>
      <Checkout />
    </StyledLayout>
  );
};

export default CheckoutPage;
