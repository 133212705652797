import Button from "components/Button";
import { FormattedMessage } from "react-intl";
import {
  ButtonContainer,
  Container,
  CardContainer,
  HowItWorksContainer,
  StepsContainer,
} from "./styles";

import QRIMG from "assets/images/qrcode-table.jpg";
import { useNavigate } from "react-router-dom";

const Onboarding = () => {
  const navigate = useNavigate();

  const onStart = () => {
    navigate("/register");
  };
  return (
    <Container>
      <CardContainer>
        <h1>
          <FormattedMessage id="title.congratulations" />
        </h1>
        <h2>
          <FormattedMessage
            id="message.congratulations.giftCard"
            values={{
              code: (text) => <span>{text}</span>,
            }}
          />
        </h2>
      </CardContainer>
      <HowItWorksContainer>
        <h2>
          <FormattedMessage id="title.howItWorks" />
        </h2>
        <p>
          <FormattedMessage id="description.howItWorks.giftCard.one" />
        </p>
        <span>
          <FormattedMessage
            id="description.howItWorks.giftCard.two"
            values={{
              codeOne: (text) => <span>{text}</span>,
              codeTwo: (text) => <span>{text}</span>,
            }}
          />
        </span>
        <p>
          <FormattedMessage
            id="description.howItWorks.giftCard.three"
            values={{
              code: (text) => <span>{text}</span>,
            }}
          />
        </p>
        <p>
          <FormattedMessage id="description.howItWorks.giftCard.four" />
        </p>
      </HowItWorksContainer>
      <StepsContainer>
        <h2>
          <FormattedMessage id="title.steps" />
        </h2>
        <ul>
          <li>
            <span>1</span>
            <p>
              <FormattedMessage id="description.steps.giftCard.one" />
            </p>
          </li>
          <li>
            <span>2</span>
            <p>
              <FormattedMessage id="description.steps.giftCard.two" />
            </p>
          </li>
          <li>
            <span>3</span>
            <p>
              <FormattedMessage id="description.steps.giftCard.three" />
            </p>
          </li>
          <li>
            <span>4</span>
            <p>
              <FormattedMessage id="description.steps.giftCard.four" />
              <img src={QRIMG} alt="qrcode" />
            </p>
          </li>
        </ul>
      </StepsContainer>

      <ButtonContainer>
        <Button type="button" onClick={onStart}>
          <FormattedMessage id="button.startNow" />
        </Button>
      </ButtonContainer>
      <p>
        <FormattedMessage
          id="message.awesome.giftCard"
          values={{
            code: (text) => <span>{text}</span>,
          }}
        />
      </p>
    </Container>
  );
};
export default Onboarding;
