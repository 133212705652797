import Loading from "components/Loading";
import {
  StyledHeader,
  StyledLayout,
  StyledMessage,
} from "libs/styled-components/styles";
import EventSection from "modules/openEvent/components/EventSection";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import { useGetOpenEventById } from "services/events";
import { Content } from "./styles";

const Main = () => {
  const params = useParams();

  const { data: event, isLoading } = useGetOpenEventById(params.id);

  if (!event || isLoading) {
    return <Loading />;
  }
  if (!event) {
    return (
      <StyledMessage>
        <h3>
          <FormattedMessage id="event.notFound" />
        </h3>
      </StyledMessage>
    );
  }
  return (
    <StyledLayout>
      <StyledHeader>
        <h3>{event.title}</h3>
      </StyledHeader>
      <Content>
        <EventSection event={event} />
      </Content>
    </StyledLayout>
  );
};
export default Main;
