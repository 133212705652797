import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 1rem;
`;

export const BlockedGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 2rem;
  gap: 1rem;
  background-color: ${({ theme }) => theme.colors.light[600]};
  color: ${({ theme }) => theme.colors.text.dark};

  & > label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    font-size: 1.6rem;
    & > span {
      color: ${({ theme }) => theme.colors.primary.default};
      font-family: "Montserrat Medium", sans-serif;
    }
  }
  @media (${({ theme }) => theme.medias.tablet}) {
    & > h3 {
      font-size: 3rem;
    }
    & > label {
      font-size: 2rem;
    }
  }
`;

export const GroupInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding: 1rem;
  gap: 1rem;
  & > h3 {
  }
  & > label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    font-size: 1.6rem;
    & > span {
    }
  }
  @media (${({ theme }) => theme.medias.tablet}) {
    & > h3 {
      font-size: 2.2rem;
    }
    & > label {
      font-size: 1.8rem;
      & > span {
      }
    }
  }
`;

export const GroupList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 1rem;
  gap: 1rem;
  @media (${({ theme }) => theme.medias.tablet}) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;
