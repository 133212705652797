import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import GlobalContext from "context";
import FirebaseFirestoreService from "libs/firebase/FirebaseFirestoreService";
import { useContext } from "react";
import { saveLog } from "services/logs";

const COLLECTION = "events";

export const useGetEvents = (options) => {
  const { user } = useContext(GlobalContext);
  return useQuery(
    [COLLECTION, "useGetEvents"],
    async () => {
      try {
        const response = await FirebaseFirestoreService.readDocuments({
          collection: COLLECTION,
          queries: [
            {
              field: "userOwnerEmail",
              condition: "==",
              value: user.email,
            },
            {
              field: "status",
              condition: "!=",
              value: "draft",
            },
          ],
        });
        const events = response.docs.map((doc) => {
          const id = doc.id;
          const data = doc.data();
          return {
            ...data,
            id,
          };
        });
        return events;
      } catch (error) {
        const errorMsg = `useGetEvents [${JSON.stringify(error)}]`;
        console.log(errorMsg);
      }
    },
    {
      ...options,
    }
  );
};

export const useGetSharedEvents = (options) => {
  const { user } = useContext(GlobalContext);
  return useQuery(
    [COLLECTION, "useGetSharedEvents"],
    async () => {
      try {
        const response = await FirebaseFirestoreService.readDocuments({
          collection: COLLECTION,
          queries: [
            {
              field: "accessList",
              condition: "array-contains",
              value: user.email,
            },
            {
              field: "status",
              condition: "!=",
              value: "draft",
            },
          ],
        });
        const events = response.docs.map((doc) => {
          const id = doc.id;
          const data = doc.data();
          return {
            ...data,
            id,
          };
        });
        return events;
      } catch (error) {
        const errorMsg = `useGetSharedEvents [${JSON.stringify(error)}]`;
        console.log(errorMsg);
      }
    },
    {
      ...options,
    }
  );
};

// export const useGetInfinityEvents = (term, options) => {
//   let queryKey = null;
//   const queries = [];
//   if (term) {
//     queryKey = [COLLECTION, "useGetEvents", term];
//     queries.push({
//       field: "searchIndexes",
//       condition: "array-contains",
//       value: term,
//     });
//   } else {
//     queryKey = [COLLECTION, "useGetEvents"];
//   }

//   const fetchEvents = async ({ pageParam = "" }) => {
//     try {
//       const response = await FirebaseFirestoreService.readDocuments({
//         collection: COLLECTION,
//         queries,
//         perPage: PER_PAGE,
//         cursorId: pageParam,
//       });
//       const events = response.docs.map((doc) => {
//         const id = doc.id;
//         const data = doc.data();
//         return {
//           ...data,
//           id,
//         };
//       });
//       return events;
//     } catch (error) {
//       const errorMsg = `useGetInfinityEvents.fetchEvents [${JSON.stringify(
//         error
//       )}]`;
//       console.log(errorMsg);
//     }
//   };
//   return useInfiniteQuery(queryKey, fetchEvents, {
//     getNextPageParam: (lastPage) => {
//       if (!lastPage.length) return undefined;
//       const lastDoc = lastPage[lastPage.length - 1];
//       return lastDoc.id;
//     },
//     ...options,
//   });
// };

export const useGetEventById = (eventId, options) => {
  const { user } = useContext(GlobalContext);

  return useQuery(
    [COLLECTION, "useGetEventById", eventId],
    async () => {
      try {
        const response = await FirebaseFirestoreService.readDocument(
          COLLECTION,
          eventId
        );
        const id = response.id;
        const data = response.data();
        // grant access only to the owner
        if (data.userOwnerEmail !== user.email) {
          return null;
        }
        return {
          ...data,
          id,
        };
      } catch (error) {
        const errorMsg = `useGetEventById.readDocument [${JSON.stringify(
          error
        )}]`;
        console.log(errorMsg);
      }
    },
    {
      ...options,
    }
  );
};

export const useGetOpenEventById = (eventId, options) => {
  return useQuery(
    [COLLECTION, "useGetOpenEventById", eventId],
    async () => {
      try {
        const response = await FirebaseFirestoreService.readDocument(
          COLLECTION,
          eventId
        );
        const id = response.id;
        const data = response.data();

        // grant access only open events
        if (data.status !== "open") {
          return null;
        }

        return {
          ...data,
          id,
        };
      } catch (error) {
        const errorMsg = `useGetOpenEventById.readDocument [${JSON.stringify(
          error
        )}]`;
        console.log(errorMsg);
      }
    },
    {
      ...options,
    }
  );
};

export const useGetEventToWatchById = (eventId, options) => {
  const { user } = useContext(GlobalContext);

  return useQuery(
    [COLLECTION, "useGetEventToWatchById", eventId],
    async () => {
      try {
        const response = await FirebaseFirestoreService.readDocument(
          COLLECTION,
          eventId
        );
        const id = response.id;
        const data = response.data();
        // grant access only to the owner or if user email is present on mailList
        if (
          data.userOwnerEmail !== user.email &&
          !data.accessList.includes(user.email)
        ) {
          return null;
        }

        return {
          ...data,
          id,
        };
      } catch (error) {
        const errorMsg = `useGetEventById.readDocument [${JSON.stringify(
          error
        )}]`;
        console.log(errorMsg);
      }
    },
    {
      ...options,
    }
  );
};

export const useCreateEvent = (options) => {
  const queryClient = useQueryClient();
  const { user, userMarket, language } = useContext(GlobalContext);
  return useMutation(
    async (payload) => {
      try {
        const code = await generateRandomCode();

        const searchIndexes = [
          payload.date,
          user.id,
          user.email.toLowerCase(),
          user.name.toLowerCase(),
          payload.code ? payload.code : null,
          payload.title ? payload.title.toLowerCase() : null,
        ];
        const data = {
          ...payload,
          userOwner: user.id,
          userOwnerEmail: user.email,
          userOwnerName: user.name,
          market: userMarket,
          language,
          code,
          searchIndexes,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        };

        const response = await FirebaseFirestoreService.createDocument(
          COLLECTION,
          data
        );
        saveLog({
          action: "create-event",
          event: response.id,
          user: user.id,
          data: {
            ...data,
          },
        });
        return { id: response.id, ...data };
      } catch (error) {
        const errorMsg = `useCreateEvents.createDocument [${JSON.stringify(
          error
        )}]`;
        console.log(errorMsg);
      }
    },
    {
      onSuccess: (result) => {
        queryClient.removeQueries([COLLECTION, "useGetEvents"], {
          exact: true,
        });
      },
      ...options,
    }
  );
};

export const useUpdateEvent = (options) => {
  const { user, userMarket } = useContext(GlobalContext);
  const queryClient = useQueryClient();
  return useMutation(
    async (payload) => {
      let data = {};

      const searchIndexes = [
        payload.date,
        user.id,
        user.email.toLowerCase(),
        user.name.toLowerCase(),
        payload.code ? payload.code : null,
        payload.title ? payload.title.toLowerCase() : null,
        payload.id,
        payload.refId ? payload.refId : null,
        payload.refType ? payload.refType : null,
        payload.refEmail ? payload.refEmail.toLowerCase() : null,
      ];

      if (payload.userOwner) {
        data = {
          ...payload,
          searchIndexes,
          updatedAt: new Date().toISOString(),
        };
      } else {
        data = {
          ...payload,
          userOwner: user.id,
          userOwnerName: user.name,
          market: userMarket,
          searchIndexes,
          updatedAt: new Date().toISOString(),
        };
      }

      try {
        await FirebaseFirestoreService.updateDocument(
          COLLECTION,
          payload.id,
          data
        );
        saveLog({
          action: "update-event",
          event: payload.id,
          user: user.id,
          data: {
            ...data,
          },
        });
        return data;
      } catch (error) {
        const errorMsg = `useUpdateEvent.updateDocument [${JSON.stringify(
          error
        )}]`;
        console.log(errorMsg);
      }
    },
    {
      onSuccess: (result) => {
        const queryKey = [COLLECTION, "useGetEventById", result.id];
        queryClient.removeQueries([COLLECTION, "useGetEvents"], {
          exact: true,
        });
        queryClient.setQueryData(queryKey, (oldData) => ({
          ...oldData,
          ...result,
        }));
      },
      ...options,
    }
  );
};

const generateRandomCode = async () => {
  try {
    const randomCode = Math.floor(100000 + Math.random() * 900000).toString();
    const response = await FirebaseFirestoreService.readDocuments({
      collection: COLLECTION,
      queries: [
        {
          field: "code",
          condition: "==",
          value: randomCode,
        },
      ],
    });
    if (response.docs.length > 0) {
      return generateRandomCode();
    } else {
      return randomCode;
    }
  } catch (error) {
    const errorMsg = `generateRandomCode [${JSON.stringify(error)}]`;
    console.log(errorMsg);
  }
};
