import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 2rem;
  gap: 2rem;
  @media (${({ theme }) => theme.medias.tablet}) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 2rem 4rem;
    height: 100%;
  }
`;

export const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.primary.light};
  border: 1px solid ${({ theme }) => theme.colors.primary.default};
  border-radius: 0.5rem;
  overflow: hidden;
  @media (${({ theme }) => theme.medias.tablet}) {
    max-width: 25.5rem;
    align-self: stretch;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  padding: 1rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 0.5rem;
  }
  @media (${({ theme }) => theme.medias.tablet}) {
    flex-direction: row;
  }
`;

export const DescriptionContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  & > h2 {
    font-family: "Montserrat Medium";
    text-align: center;
    width: 100%;
    font-size: 2rem;
    margin-bottom: 1rem;
  }
`;
