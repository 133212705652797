import { useEffect, useRef, useState } from "react";
import WizardCreateEvent from "./components/WizardCreateEvent";
import WizardAddBanner from "./components/WizardAddBanner";
import WizardPayment from "./components/WizardPayment";
import { useCookies } from "react-cookie";
import { getAffiliateByEmail } from "services/affiliates";
import { getPartnerByEmail } from "services/partners";
import { AES, enc } from "crypto-js";
import { Buffer } from "buffer";

const WizardForm = () => {
  const [step, setStep] = useState(1);
  const [event, setEvent] = useState(null);

  const referral = useRef(null);

  const nextStep = () => {
    setStep((prev) => prev + 1);
  };

  const [cookies] = useCookies();

  useEffect(() => {
    const getRefCookie = async () => {
      if (cookies.cdt_ref) {
        const { email, type } = cookies.cdt_ref;
        let refUser = null;
        if (type === "affiliate") {
          const hexToBase64 = Buffer.from(email, "hex").toString("base64");
          const bytesEmail = AES.decrypt(
            hexToBase64,
            process.env.REACT_APP_AES_SECRET_KEY
          );
          const decryptEmail = bytesEmail.toString(enc.Utf8);

          refUser = await getAffiliateByEmail(decryptEmail);
          referral.current = { ...refUser, type };
        } else if (type === "partner") {
          refUser = await getPartnerByEmail(email);
          referral.current = { ...refUser, type };
        }
      }
    };
    getRefCookie();
  }, [cookies]);

  // //TODO: fix this
  // const { data: event, isLoading: isGetting } = useGetEventById(
  //   "aPpm4X1PWoLksLBl7j1J"
  // );
  // console.log("event", event);

  // if (isGetting) {
  //   return <div>Carregando evento...</div>;
  // }

  if (step === 2) {
    return <WizardAddBanner step={step} event={event} nextStep={nextStep} />;
  }

  if (step === 3) {
    return (
      <WizardPayment step={step} event={event} referral={referral.current} />
    );
  }

  // default is the first step
  return (
    <WizardCreateEvent step={step} setEvent={setEvent} nextStep={nextStep} />
  );
};
export default WizardForm;
