import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 2rem;
  padding-bottom: 10rem;
  gap: 1rem;
  & > h1 {
    text-align: left;
    font-size: 2.4rem;
  }
  @media (${({ theme }) => theme.medias.tablet}) {
    padding: 0 4rem;
    padding-bottom: 4rem;
  }
`;

export const WatchList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: 100%;
`;

export const WatchItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.primary.default};
  border-radius: 0.5rem;
  overflow: hidden;
  @media (${({ theme }) => theme.medias.tablet}) {
    gap: 2rem;
  }
`;

export const ItemImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 15rem;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 0.5rem;
  }
  @media (${({ theme }) => theme.medias.tablet}) {
    max-width: 30rem;
  }
`;
