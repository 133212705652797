import { useMutation } from "@tanstack/react-query";
import GlobalContext from "context";
import FirebaseFirestoreService from "libs/firebase/FirebaseFirestoreService";
import { useContext } from "react";
const COLLECTION = "users";

export const useCreateUser = (options) => {
  const { userHandler } = useContext(GlobalContext);
  return useMutation(
    async (payload) => {
      try {
        const data = {
          ...payload,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        };
        const response = await FirebaseFirestoreService.createDocumentWithId(
          COLLECTION,
          payload.id,
          data
        );

        return response;
      } catch (error) {
        const errorMsg = `useCreateUser.createDocumentWithId [${JSON.stringify(
          error
        )}]`;
        console.log(errorMsg);
      }
    },
    {
      onSuccess: (result) => {
        userHandler(result);
      },
      ...options,
    }
  );
};

export const useUpdateUser = (options) => {
  const { userHandler } = useContext(GlobalContext);
  return useMutation(
    async (payload) => {
      try {
        const data = {
          ...payload,
          updatedAt: new Date().toISOString(),
        };
        await FirebaseFirestoreService.updateDocument(
          COLLECTION,
          payload.id,
          data
        );

        return data;
      } catch (error) {
        const errorMsg = `useUpdateUser.updateDocument [${JSON.stringify(
          error
        )}]`;
        console.log(errorMsg);
      }
    },
    {
      onSuccess: (result) => {
        userHandler(result);
      },
      ...options,
    }
  );
};

export const getUserByEmail = async (email) => {
  try {
    const response = await FirebaseFirestoreService.readDocuments({
      collection: COLLECTION,
      queries: [
        {
          field: "email",
          condition: "==",
          value: email,
        },
      ],
    });

    if (response.docs.length === 0) return null;
    return response.docs[0].data();
  } catch (error) {
    const errorMsg = `getUserByEmail.readDocuments [${JSON.stringify(error)}]`;
    console.log(errorMsg);
  }
};
