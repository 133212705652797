import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { default as VimeoPlayer } from "@vimeo/player";
import {
  ActionContainer,
  Container,
  DownloadContainer,
  ShareContainer,
  VideoInfo,
  VideoWrapper,
} from "./styles";
import { parseVimeoId } from "utils";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import AutoplayControl from "../AutoplayControl";
import SimpleModal from "components/SimpleModal";
import { saveLog } from "services/logs";
import GlobalContext from "context";
import { Download, Share } from "assets/svg";
import Spinner from "components/Spinner";
import { downloadVideo } from "services/videos";

const VideoPlayer = ({ video, playNextVideo }) => {
  const { user } = useContext(GlobalContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const playerContainer = useRef(null);
  const playerRef = useRef(null);
  const isAutoPlay = useRef(false);
  const timeout = useRef(null);
  const intl = useIntl();

  const playNext = useCallback(() => {
    if (isAutoPlay.current) {
      playNextVideo();
    }
  }, [playNextVideo, isAutoPlay]);

  const shareURL = useMemo(() => {
    return `https://webapp.capsuladotempo.com/share-video/${parseVimeoId(
      video.vimeoUrl
    )}`;
  }, [video]);

  const handleShare = useCallback(() => {
    playerRef.current.pause();

    if (navigator.share) {
      navigator
        .share({
          title: "Cápsula do Tempo",
          text: intl.formatMessage({ id: "message.sharedVideo" }),
          url: shareURL,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    } else {
      setIsModalOpen(true);
    }
  }, [shareURL, intl]);

  const onCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const onCopyToClipboard = async () => {
    navigator.clipboard.writeText(shareURL);
    setIsCopied(true);
    saveLog({
      action: "copy-link",
      user: user.id,
      video: video.id,
      data: {
        component: "VideoPlayer",
        link: shareURL,
        email: user.email,
      },
    });
    timeout.current = setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  const downloadVimeoVideo = async () => {
    setIsDownloading(true);
    playerRef.current.pause();
    const videoId = parseVimeoId(video.vimeoUrl);
    const fileName = `video-${video.videoNumber}.mp4`; // Nome do arquivo de vídeo
    try {
      downloadVideo(videoId, fileName, () => {
        setIsDownloading(false);
      });
      saveLog({
        action: "download-video",
        user: user.id,
        video: video.id,
        data: {
          component: "VideoPlayer",
          link: shareURL,
          vimeoId: videoId,
          email: user.email,
        },
      });
    } catch (error) {
      console.error(error);
      setIsDownloading(false);
    }
  };

  useEffect(() => {
    playerRef.current = new VimeoPlayer(playerContainer.current, {
      id: parseVimeoId(video.vimeoUrl),
      autopause: true,
      autoplay: false,
    });
    playerRef.current.on("ended", playNext);
    return () => {
      playerRef.current.off("ended", playNext);
      playerRef.current.unload();
    };
  }, [video, playNext]);

  useEffect(() => {
    if (playerRef.current) {
      const vimeoId = parseVimeoId(video.vimeoUrl);
      playerRef.current.loadVideo(vimeoId).then(function () {
        playerRef.current.play();
      });
    }
  }, [video]);

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current);
    };
  }, []);

  return (
    <>
      <Container>
        <VideoWrapper>
          <div ref={playerContainer} />
        </VideoWrapper>
        <VideoInfo>
          <h3>
            {video.videoNumber ? (
              <>#{video.videoNumber}</>
            ) : (
              <FormattedDate value={video.createdAt} />
            )}
          </h3>{" "}
          <ActionContainer>
            <button onClick={handleShare}>
              <Share
                width={20}
                height={20}
                outline="#ffffff"
                color="transparent"
              />
            </button>
            {video.videoNumber && (
              <button onClick={downloadVimeoVideo} disabled={isDownloading}>
                <Download
                  width={20}
                  height={20}
                  outline="#ffffff"
                  color="transparent"
                />
              </button>
            )}
          </ActionContainer>
        </VideoInfo>
        <AutoplayControl isAutoPlay={isAutoPlay} />
      </Container>
      <SimpleModal
        isOpen={isModalOpen}
        handleClose={onCloseModal}
        title={<FormattedMessage id="title.shareVideo" />}
      >
        <ShareContainer isCopied={isCopied}>
          <h3>
            <FormattedMessage id="description.shareVideo" />
          </h3>
          <button onClick={onCopyToClipboard}>
            {isCopied ? <FormattedMessage id="button.copied" /> : shareURL}
          </button>
        </ShareContainer>
      </SimpleModal>
      <SimpleModal
        isOpen={isDownloading}
        title={<FormattedMessage id="title.downloadVideo" />}
      >
        <DownloadContainer>
          <h3>
            <FormattedMessage id="message.downloadVideo" />
          </h3>
          <Spinner color="#f76c6f" />
        </DownloadContainer>
      </SimpleModal>
    </>
  );
};

export default VideoPlayer;
