import Button from "components/Button";
import { StyledFullLayout } from "libs/styled-components/styles";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { ButtonContainer, Container } from "./styles";

const PaymentCanceled = () => {
  const navigate = useNavigate();
  const goToCreateEvent = () => {
    navigate("../events/new", { replace: true });
  };

  const goToLandingPage = () => {
    navigate("../events", { replace: true });
  };

  return (
    <StyledFullLayout>
      <Container>
        <h1>
          <FormattedMessage id="payment.canceled" />
        </h1>
        <p>
          <FormattedMessage id="message.payment.canceled.options" />
        </p>
        <ButtonContainer>
          <Button type="button" onClick={goToCreateEvent}>
            <FormattedMessage id="button.tryAgain" />
          </Button>
          <Button color="secondary" type="button" onClick={goToLandingPage}>
            <FormattedMessage id="button.goToHome" />
          </Button>
        </ButtonContainer>
      </Container>
    </StyledFullLayout>
  );
};
export default PaymentCanceled;
