import { FormattedMessage } from "react-intl";
import { Control } from "./styles";
import { useCallback, useState } from "react";

const AutoplayControl = ({ isAutoPlay }) => {
  const [autoPlay, setAutoPlay] = useState(isAutoPlay.current);

  const onAutoPlayHandler = useCallback(() => {
    setAutoPlay((prev) => {
      isAutoPlay.current = !prev;
      return !prev;
    });
  }, [isAutoPlay]);

  return (
    <Control autoPlay={autoPlay}>
      <label>
        <FormattedMessage id="label.autoplay" />
        <button onClick={onAutoPlayHandler}>
          {autoPlay ? (
            <FormattedMessage id="button.on" />
          ) : (
            <FormattedMessage id="button.off" />
          )}
        </button>
      </label>
    </Control>
  );
};

export default AutoplayControl;
