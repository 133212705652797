import { FormattedMessage } from "react-intl";
import { StepCircle, StepContainer, StepItem } from "./styles";

const StepMeter = ({ step }) => {
  return (
    <StepContainer>
      <StepItem>
        <label>
          <FormattedMessage id="wizard.step1" />
        </label>
        <StepCircle active={step === 1}>1</StepCircle>
      </StepItem>
      <StepItem>
        <label>
          <FormattedMessage id="wizard.step2" />
        </label>
        <StepCircle active={step === 2}>2</StepCircle>
      </StepItem>
      <StepItem>
        <label>
          <FormattedMessage id="wizard.step3" />
        </label>
        <StepCircle active={step === 3}>3</StepCircle>
      </StepItem>
      <StepItem>
        <label>
          <FormattedMessage id="wizard.step4" />
        </label>
        <StepCircle active={step === 4}>4</StepCircle>
      </StepItem>
    </StepContainer>
  );
};
export default StepMeter;
