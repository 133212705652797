import Loading from "components/Loading";
import VideoWatcher from "components/VideoWatcher";
import { StyledMessage } from "libs/styled-components/styles";
import EventNotHeld from "modules/videos/components/EventNotHeld";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import { useGetEventToWatchById } from "services/events";

const Videos = () => {
  const params = useParams();
  const { data: event, isLoading } = useGetEventToWatchById(params.id);

  if (isLoading) {
    return <Loading />;
  }

  if (!event) {
    return (
      <StyledMessage>
        <h3>
          <FormattedMessage id="message.eventNotFound" />
        </h3>
      </StyledMessage>
    );
  }

  if (!event.playlist || event.status === "published") {
    return <EventNotHeld event={event} />;
  }

  return <VideoWatcher videoList={event.playlist} />;
};

export default Videos;
