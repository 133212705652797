import PayButton from "../PayButton";
import RedeemGiftCard from "../RedeemGiftCard";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import StepMeter from "../StepMeter";
import PartnerPurchase from "../PartnerPurchase";

const { Container, BorderContainer, LinkButton } = require("./styles");

const WizardPayment = ({ step, event, referral }) => {
  const [isGiftCard, setIsGiftCard] = useState(false);

  return (
    <Container>
      <StepMeter step={step} />
      {/* have a gift card */}
      {isGiftCard && (
        <>
          <BorderContainer>
            <RedeemGiftCard event={event} />
          </BorderContainer>
          <LinkButton onClick={() => setIsGiftCard(false)}>
            <FormattedMessage id="button.dontHaveGiftCard" />
          </LinkButton>
        </>
      )}
      {/* if referral is partner */}
      {!isGiftCard && referral?.type === "partner" && (
        <>
          <BorderContainer>
            <PartnerPurchase event={event} referral={referral} />
          </BorderContainer>
          <LinkButton onClick={() => setIsGiftCard(true)}>
            <FormattedMessage id="button.haveGiftCard" />
          </LinkButton>
        </>
      )}
      {/* if referral is affiliate */}
      {!isGiftCard && referral?.type === "affiliate" && (
        <>
          <BorderContainer>
            <PayButton event={event} />
          </BorderContainer>
          <LinkButton onClick={() => setIsGiftCard(true)}>
            <FormattedMessage id="button.haveGiftCard" />
          </LinkButton>
        </>
      )}
      {/* if referral is null - no referral */}
      {!isGiftCard && referral === null && (
        <>
          <BorderContainer>
            <PayButton event={event} />
          </BorderContainer>
          <LinkButton onClick={() => setIsGiftCard(true)}>
            <FormattedMessage id="button.haveGiftCard" />
          </LinkButton>
        </>
      )}
    </Container>
  );
};
export default WizardPayment;
