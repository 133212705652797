import Loading from "components/Loading";
import {
  StyledContent,
  StyledHeader,
  StyledLayout,
  StyledMessage,
} from "libs/styled-components/styles";
import EventForm from "modules/events/components/Form";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import { useGetEventById } from "services/events";

const EditEvent = () => {
  const params = useParams();

  const { data: event, isLoading } = useGetEventById(params.id);

  const isEditable = useMemo(() => {
    const eventDate = new Date(event?.date);
    const today = new Date();
    return eventDate > today;
  }, [event]);

  if (!isEditable) {
    return (
      <StyledMessage>
        <h3>
          <FormattedMessage id="event.notEditable" />
        </h3>
      </StyledMessage>
    );
  }

  if (isLoading) {
    return <Loading />;
  }

  if (!event) {
    return (
      <StyledMessage>
        <h3>
          <FormattedMessage id="event.notFound" />
        </h3>
      </StyledMessage>
    );
  }

  return (
    <StyledLayout>
      <StyledHeader>
        <h1>
          <FormattedMessage id="event.edit" />
        </h1>
      </StyledHeader>
      <StyledContent>
        <EventForm event={event} />
      </StyledContent>
    </StyledLayout>
  );
};
export default EditEvent;
