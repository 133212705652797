import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;

  background-color: ${(p) => p.theme.colors.primary.default};
  color: ${(p) => p.theme.colors.text.light};
  z-index: 10;

  & > div {
    cursor: pointer;
  }
`;

export const LeftContainer = styled.div`
  min-width: 10rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const RightContainer = styled.div`
  min-width: 10rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
`;

export const LogoutButton = styled.button`
  display: flex;
  align-self: stretch;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: none;
  background-color: ${(p) => p.theme.colors.light.default};
  border: 1px solid ${(p) => p.theme.colors.primary.default};
  color: ${(p) => p.theme.colors.primary.default};
  gap: 0.6rem;
  cursor: pointer;
`;
