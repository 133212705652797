import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SelectLanguageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  padding-bottom: 3rem;
  gap: 2rem;
  label {
    max-width: 16rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.8rem;
  }
`;
