import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useGetSharedEvents } from "services/events";
import { Container, WatchList, WatchItem, ItemImage } from "./styles";

const SharedEventList = () => {
  const { data } = useGetSharedEvents();
  const navigate = useNavigate();
  if (data && data.length === 0) {
    return null;
  }
  const onWatch = (id) => {
    navigate(`/videos/${id}`);
  };
  return (
    <Container>
      {data && data.length > 0 && (
        <>
          <h1>
            <FormattedMessage id="events.shared.you" />
          </h1>
          <WatchList>
            {data?.map((event) => (
              <WatchItem key={event.id} onClick={() => onWatch(event.id)}>
                <ItemImage>
                  <img src={event.banner} alt={event.title} />
                </ItemImage>
                <h3>{event.title}</h3>
              </WatchItem>
            ))}
          </WatchList>
        </>
      )}
    </Container>
  );
};

export default SharedEventList;
