import { StyledFullLayout } from "libs/styled-components/styles";
import SuccessMessage from "modules/giftCard/components/SuccessMessage";
const Success = () => {
  return (
    <StyledFullLayout>
      <SuccessMessage />
    </StyledFullLayout>
  );
};

export default Success;
