import Button from "components/Button";
import DateInput from "components/DateInput";
import InputText from "components/InputText";
import Spinner from "components/Spinner";
import {
  StyledButtonArea,
  StyledForm,
  StyledLine,
} from "libs/styled-components/styles";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useCreateEvent } from "services/events";
import StepMeter from "../StepMeter";
import { Container, CustomInput, MessageContainer } from "./styles";
import { convertToDate, dateToString, formatDate } from "utils";
import GlobalContext from "context";

const WizardCreateEvent = ({ step, setEvent, nextStep }) => {
  const { userMarket } = useContext(GlobalContext);
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const { mutate: create, isLoading } = useCreateEvent();
  const [acceptTerms, setAcceptTerms] = useState(false);
  const termsAcceptedDate = useRef(null);

  const navigate = useNavigate();

  const watchDate = watch("date");

  const minDate = useMemo(() => {
    if (watchDate) {
      const minBaseDate = new Date(watchDate);
      minBaseDate.setMonth(minBaseDate.getMonth() + 1);
      return dateToString(minBaseDate);
    }
    return null;
  }, [watchDate]);

  const maxDate = useMemo(() => {
    if (watchDate) {
      const maxBaseDate = new Date(watchDate);
      maxBaseDate.setFullYear(maxBaseDate.getFullYear() + 5);
      return dateToString(maxBaseDate);
    }
    return null;
  }, [watchDate]);

  const onCancel = () => {
    navigate("/");
  };

  const validateDate = (newDate) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const date = new Date(newDate);
    date.setHours(0, 0, 0, 0);

    if (date <= today) {
      return <FormattedMessage id="error.dateGreaterOrEqualToday" />;
    }
  };

  /**
   * newDate must be greater minDate
   * newDate must be less than maxDate
   **/
  const validateReleaseDate = (newDate) => {
    const date = new Date(newDate);
    date.setHours(0, 0, 0, 0);
    const min = convertToDate(minDate);
    const max = convertToDate(maxDate);

    if (date < min) {
      return <FormattedMessage id="error.dateGreaterOrEqualOneMonthLater" />;
    }
    if (date > max) {
      return <FormattedMessage id="error.dateLessOrEqualFiveYearsLater" />;
    }
  };

  const onAcceptTermsHandler = () => {
    setAcceptTerms((prev) => {
      if (prev) {
        termsAcceptedDate.current = null;
      } else {
        termsAcceptedDate.current = new Date().toISOString();
      }
      return !prev;
    });
  };

  const termsLink = useMemo(() => {
    return `https://webapp.capsuladotempo.com/terms-and-privacy`;
  }, []);

  const isDisabled = useMemo(() => {
    return !acceptTerms;
  }, [acceptTerms]);

  const onCreateEvent = useCallback(
    (data) => {
      //convert date to String
      data.date = dateToString(data.date);
      //convert releaseDate to String
      data.releaseDate = dateToString(data.releaseDate);
      const newData = {
        ...data,
        status: "draft",
        termsAcceptedDate: termsAcceptedDate.current,
      };

      create(newData, {
        onSuccess: (result) => {
          setEvent(result);
          nextStep();
        },
      });
    },
    [create, nextStep, setEvent, termsAcceptedDate]
  );

  return (
    <Container>
      <StepMeter step={step} />
      <StyledForm onSubmit={handleSubmit(onCreateEvent)}>
        <StyledLine>
          <InputText
            label={<FormattedMessage id="label.title" />}
            field="title"
            errors={errors}
            required={true}
            register={register}
          />
        </StyledLine>
        <StyledLine>
          <DateInput
            label={<FormattedMessage id="label.date" />}
            name="date"
            control={control}
            validate={validateDate}
            errors={errors}
          />
        </StyledLine>
        <StyledLine>
          <DateInput
            label={<FormattedMessage id="label.releaseDate" />}
            name="releaseDate"
            minDate={minDate}
            maxDate={maxDate}
            control={control}
            validate={validateReleaseDate}
            errors={errors}
          />
          {watchDate && (
            <MessageContainer>
              <FormattedMessage
                id="message.releaseDate"
                values={{
                  minDate: formatDate(userMarket, minDate),
                  maxDate: formatDate(userMarket, maxDate),
                }}
              />
            </MessageContainer>
          )}
        </StyledLine>
        <>
          <StyledLine>
            <CustomInput>
              <label>
                <input
                  type="checkbox"
                  onChange={onAcceptTermsHandler}
                  checked={acceptTerms}
                />
                <span></span>
              </label>
              <div>
                <FormattedMessage
                  id="message.event.terms"
                  values={{
                    code: (text) => (
                      <a href={termsLink} target="_blank" rel="noreferrer">
                        {text}
                      </a>
                    ),
                  }}
                />
              </div>
            </CustomInput>
          </StyledLine>
        </>

        <StyledButtonArea>
          <Button type="button" color="secondary" onClick={onCancel}>
            <FormattedMessage id="button.goBack" />
          </Button>

          <Button type="submit" color="primary" disabled={isDisabled}>
            {isLoading ? <Spinner /> : <FormattedMessage id="button.next" />}
          </Button>
        </StyledButtonArea>
      </StyledForm>
    </Container>
  );
};
export default WizardCreateEvent;
