import {
  BrowserRouter,
  Routes as BrowserRoutes,
  Route,
} from "react-router-dom";
import PublicRouter from "./PublicRouter";
import PrivateRouter from "./PrivateRouter";
import OpenRouter from "./OpenRouter";
import Login from "modules/login/screens/Main";
import Register from "modules/register/screens/Main";
import Events from "modules/events/screens/Main";
import CreateEvent from "modules/events/screens/Create";
import ViewEvent from "modules/events/screens/View";
import EditEvent from "modules/events/screens/Edit";
import ForgotPassword from "modules/forgotPassword/screens/Main";
import Print from "modules/print/screens/Main";
import PaymentSuccess from "modules/events/screens/PaymentSuccess";
import PaymentCanceled from "modules/events/screens/PaymentCanceled";
import Videos from "modules/videos/screens/Main";
import OpenEvent from "modules/openEvent/screens/Main";
import OpenWatch from "modules/openEvent/screens/Watch";
import OpenPrint from "modules/openEvent/screens/Print";
import Conditions from "modules/terms/screens/Conditions";
import GiftCard from "modules/giftCard/screens/Main";
import Success from "modules/giftCard/screens/Success";
import Cancel from "modules/giftCard/screens/Cancel";
import CheckoutPage from "modules/giftCard/screens/Checkout";
import ShareVideo from "modules/videos/screens/ShareVideo";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <BrowserRoutes>
        <Route
          path="/"
          element={
            <PrivateRouter>
              <Events />
            </PrivateRouter>
          }
        />
        <Route
          path="/events"
          element={
            <PrivateRouter>
              <Events />
            </PrivateRouter>
          }
        />
        <Route
          path="/events/:id"
          element={
            <PrivateRouter>
              <ViewEvent />
            </PrivateRouter>
          }
        />
        <Route
          path="/events/new"
          element={
            <PrivateRouter>
              <CreateEvent />
            </PrivateRouter>
          }
        />
        <Route
          path="/events/edit/:id"
          element={
            <PrivateRouter>
              <EditEvent />
            </PrivateRouter>
          }
        />
        <Route
          path="/events/print/:id"
          element={
            <PrivateRouter>
              <Print />
            </PrivateRouter>
          }
        />
        <Route
          path="/events/paymentSuccess/:id"
          element={
            <PrivateRouter>
              <PaymentSuccess />
            </PrivateRouter>
          }
        />
        <Route
          path="/events/paymentCanceled/:id"
          element={
            <PrivateRouter>
              <PaymentCanceled />
            </PrivateRouter>
          }
        />
        <Route
          path="/videos/:id"
          element={
            <PrivateRouter>
              <Videos />
            </PrivateRouter>
          }
        />
        <Route
          path="/share-video/:id"
          element={
            <OpenRouter>
              <ShareVideo />
            </OpenRouter>
          }
        />
        <Route
          path="/login"
          element={
            <PublicRouter>
              <Login />
            </PublicRouter>
          }
        />
        <Route
          path="/register"
          element={
            <PublicRouter>
              <Register />
            </PublicRouter>
          }
        />
        <Route
          path="/open-event/:id"
          element={
            <OpenRouter>
              <OpenEvent />
            </OpenRouter>
          }
        />
        <Route
          path="/open-event/watch/:id"
          element={
            <OpenRouter>
              <OpenWatch />
            </OpenRouter>
          }
        />
        <Route
          path="/open-event/print/:id"
          element={
            <OpenRouter>
              <OpenPrint />
            </OpenRouter>
          }
        />
        <Route
          path="/gift-card"
          element={
            <OpenRouter>
              <GiftCard />
            </OpenRouter>
          }
        />
        <Route
          path="/gift-card/checkout"
          element={
            <OpenRouter>
              <CheckoutPage />
            </OpenRouter>
          }
        />
        <Route
          path="/gift-card/success/:id"
          element={
            <OpenRouter>
              <Success />
            </OpenRouter>
          }
        />
        <Route
          path="/gift-card/canceled"
          element={
            <OpenRouter>
              <Cancel />
            </OpenRouter>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <PublicRouter>
              <ForgotPassword />
            </PublicRouter>
          }
        />
        <Route path="/terms-and-privacy" element={<Conditions />} />
      </BrowserRoutes>
    </BrowserRouter>
  );
};

export default AppRouter;
