import { useForm } from "react-hook-form";
import { Container } from "./styles";
import FirebaseAuthService from "libs/firebase/FirebaseAuthService";
import { useLocation, useNavigate } from "react-router-dom";
import InputText from "components/InputText";
import { useState } from "react";
import { StyledForm, StyledLine } from "libs/styled-components/styles";
import LoginButton from "components/LoginButton";
import { GoogleIcon } from "assets/svg";
import { FormattedMessage } from "react-intl";

const Form = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      await FirebaseAuthService.loginUser(data.email, data.password);
      navigate("/");
    } catch (error) {
      setError("error.userNotFound.passwordIncorrect");
    }
  };

  const handleLoginWithGoogle = async () => {
    try {
      await FirebaseAuthService.loginWithGoogle();
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleRegister = () => {
    navigate(`../register/${location.search}`);
  };

  const handleForgotPassword = () => {
    navigate("/forgot-password");
  };

  return (
    <Container>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <StyledLine>
          <InputText
            label={<FormattedMessage id="label.email" />}
            field="email"
            type="email"
            errors={errors}
            required={true}
            register={register}
          />
        </StyledLine>
        <StyledLine>
          <InputText
            label={<FormattedMessage id="label.password" />}
            field="password"
            type="password"
            errors={errors}
            required={true}
            register={register}
          />
        </StyledLine>
        <LoginButton
          onClick={handleForgotPassword}
          type="button"
          model="forgot"
        >
          <FormattedMessage id="button.forgotPassword" />
        </LoginButton>
        {error && (
          <p>
            <FormattedMessage id={error} />
          </p>
        )}
        <LoginButton type="submit" model="login">
          <FormattedMessage id="button.login" />
        </LoginButton>
      </StyledForm>
      <LoginButton type="button" model="google" onClick={handleLoginWithGoogle}>
        <GoogleIcon height="30px" width="30px" />
        <FormattedMessage id="button.loginWithGoogle" />
        <span></span>
      </LoginButton>
      <h3>
        <FormattedMessage id="title.dontHaveAccount" />
      </h3>
      <LoginButton type="button" model="new" onClick={handleRegister}>
        <FormattedMessage id="button.signUp" />
      </LoginButton>
    </Container>
  );
};

export default Form;
