import { useEffect, useRef } from "react";
import { default as VimeoPlayer } from "@vimeo/player";
import { Container, VideoWrapper } from "./styles";
import { formatDate } from "utils";

const Player = ({ vimeoId, title, date }) => {
  const playerContainer = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    playerRef.current = new VimeoPlayer(playerContainer.current, {
      id: vimeoId,
      autopause: true,
      autoplay: false,
    });

    return () => {
      playerRef.current.unload();
    };
  }, [vimeoId]);

  return (
    <Container>
      {title && <h4>{title}</h4>}
      {date && <h4>{formatDate("", date)}</h4>}
      <VideoWrapper>
        <div ref={playerContainer} />
      </VideoWrapper>
    </Container>
  );
};

export default Player;
