import * as React from "react";
import { Container } from "./styles";
import { Logo as LogoImg } from "assets/svg";

const Logo = ({ color = "#F76C6F", height = 31, width = 88 }) => {
  return (
    <Container color={color}>
      <LogoImg outline={color} height={height} width={width} color={color} />
    </Container>
  );
};

export default Logo;
