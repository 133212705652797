import GlobalContext from "context";
import {
  StyledContent,
  StyledHeader,
  StyledLayout,
} from "libs/styled-components/styles";
import LoginForm from "modules/login/components/Form";
import VerifyEmail from "modules/login/components/VerifyEmail";
import { useContext, useMemo } from "react";
import { FormattedMessage } from "react-intl";

const Login = () => {
  const { authUser } = useContext(GlobalContext);

  const isVerifyEmail = useMemo(() => {
    return authUser ? authUser.emailVerified : false;
  }, [authUser]);

  return (
    <StyledLayout>
      {authUser && !isVerifyEmail ? (
        <VerifyEmail />
      ) : (
        <>
          <StyledHeader>
            <h1>
              <FormattedMessage id="title.login" />
            </h1>
          </StyledHeader>
          <StyledContent>
            <LoginForm />
          </StyledContent>
        </>
      )}
    </StyledLayout>
  );
};
export default Login;
