import Logo from "components/Logo";
import QRCode from "components/QRCode";
import { FormattedMessage } from "react-intl";
import { Container, Content, LogoContainer } from "./styles";

const ModelOne = ({ title, code }) => {
  return (
    <Container>
      <h1>
        <FormattedMessage id="title.messageNewlyweds" />
      </h1>
      <Content>
        <QRCode code={code} size={800} logoSize={150} />
      </Content>
      <h2>{title}</h2>
      <LogoContainer>
        <Logo color="#000" />
      </LogoContainer>
    </Container>
  );
};

export default ModelOne;
