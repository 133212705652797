import styled from "styled-components";

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.dark[100]};
  padding-bottom: 4rem;
  & > header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 2rem;
    gap: 2rem;
    color: ${({ theme }) => theme.colors.text.dark};

    & > p {
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      background-color: ${({ theme }) => theme.colors.warning[100]};
    }
  }
`;

export const ModelList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 1rem;
`;

export const ModelListItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  & > h2 {
    margin-bottom: 2rem;
  }
`;

export const ButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  & > button {
    padding: 1rem 2rem;
    font-size: large;
    border-radius: 0.5rem;
    border: 1px solid ${({ theme }) => theme.colors.primary.default};
    background-color: ${({ theme }) => theme.colors.primary.default};
    color: ${({ theme }) => theme.colors.light.default};
    cursor: pointer;
  }
`;
