import QRCode from "components/QRCode";
import { Container, Content } from "./styles";

const ModelTwo = ({ code }) => {
  return (
    <Container>
      <Content>
        <QRCode code={code} size={800} logoSize={150} />
      </Content>
    </Container>
  );
};

export default ModelTwo;
