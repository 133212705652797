import { forwardRef } from "react";
import { Container, ContainerToImage } from "./styles";

const ComponentToImabge = forwardRef((props, ref) => {
  return (
    <Container>
      <ContainerToImage ref={ref}>{props.children}</ContainerToImage>;
    </Container>
  );
});

export default ComponentToImabge;
