import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 2rem;
  @media (${({ theme }) => theme.medias.tablet}) {
    padding: 0 2rem;
    height: 100%;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const PaidContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.primary.default};
  border-radius: 0.5rem;
  padding: 2rem;
  gap: 3rem;
  margin-top: 2rem;
  & > h2 {
    text-align: center;
    font-family: "Montserrat Medium", sans-serif;
  }

  & > p {
    text-align: center;
    font-size: 1.6rem;
    @media (${({ theme }) => theme.medias.tablet}) {
      padding: 0 3rem;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    & > button,
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1.4rem;
      font-size: 2rem;
      padding: 1rem 2rem;
      font-family: "Montserrat Medium", sans-serif;
      border-radius: 0.5rem;

      cursor: pointer;
      text-decoration: none;
    }

    & > button {
      border: 1px solid ${({ theme }) => theme.colors.primary.default};
      background-color: ${({ theme }) => theme.colors.primary.default};
      color: ${({ theme }) => theme.colors.light.default};
    }

    & > a {
      border: 1px solid ${({ theme }) => theme.colors.primary.default};
      background-color: ${({ theme }) => theme.colors.light.default};
      color: ${({ theme }) => theme.colors.primary.default};
    }
    @media (${({ theme }) => theme.medias.tablet}) {
      flex-direction: row;
      gap: 5rem;
    }
  }
`;

export const WatchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  gap: 2rem;
  margin-top: 2rem;
  background-color: ${({ theme }) => theme.colors.primary.default};
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.colors.text.light};
  & > h2 {
    text-align: center;
  }
  & > button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.4rem;
    font-size: 2rem;
    padding: 1rem 2rem;
    font-family: "Montserrat Medium", sans-serif;
    border-radius: 0.5rem;

    cursor: pointer;
    text-decoration: none;
    border: 1px solid ${({ theme }) => theme.colors.light.default};
    background-color: ${({ theme }) => theme.colors.light.default};
    color: ${({ theme }) => theme.colors.primary.default};
  }
  @media (${({ theme }) => theme.medias.tablet}) {
    width: 100%;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  max-width: 50rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: 18rem;
  margin-bottom: 2rem;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  @media (${({ theme }) => theme.medias.tablet}) {
    max-height: 100%;
  }
`;

export const ListContainer = styled.div`
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 2rem;
  font-weight: bold;
  padding: 0.5rem 0;
  gap: 0.4rem;

  & > ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    list-style: none;
    gap: 0.6rem;
    & > li {
      width: 100%;
      & > div {
        width: 100%;
        margin: 0 auto;
        min-width: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: ${({ theme }) => theme.colors.light[200]};
        border-radius: 0.5rem;
        padding: 0.5rem 1rem;
        font-size: 1.4rem;
        & > span,
        label {
          max-width: 32rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        @media (${({ theme }) => theme.medias.tablet}) {
          flex-direction: row;
          font-size: 1.8rem;
          & > span {
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
`;

export const DescriptionContainer = styled.div`
  width: 100%;
  text-align: left;
  height: 100%;

  & > label {
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 2rem;
    font-weight: bold;
    padding: 0.5rem 0;
    gap: 0.4rem;
    & > span {
      background-color: ${({ theme }) => theme.colors.light[200]};
      border-radius: 0.5rem;
      padding: 0.5rem 1rem;
    }
  }
  @media (${({ theme }) => theme.medias.tablet}) {
    & > label {
      padding: 1rem 0;
    }
  }
`;

export const PublicContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  @media (${({ theme }) => theme.medias.tablet}) {
    flex-direction: row;
    gap: 2rem;
  }
`;
