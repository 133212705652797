import { StyledHeader, StyledLayout } from "libs/styled-components/styles";
import Player from "modules/videos/components/Player";

import { FormattedMessage } from "react-intl";
import { useParams, useSearchParams } from "react-router-dom";

const ShareVideo = () => {
  const params = useParams();
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const title = searchParams.get("title");
  const date = searchParams.get("date");

  return (
    <StyledLayout>
      <StyledHeader>
        <h3>
          <FormattedMessage id="title.sharedVideo" />
        </h3>
      </StyledHeader>
      <Player vimeoId={params.id} title={title} date={date} />
    </StyledLayout>
  );
};

export default ShareVideo;
