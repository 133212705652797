import { saveLog } from "services/logs";
import firebase from "./FirebaseConfig";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
  getAuth,
  sendEmailVerification,
  updateProfile,
} from "firebase/auth";

const auth = firebase.auth;

const registerUser = async (email, password, name) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    await updateProfile(userCredential.user, {
      displayName: name,
    });

    await sendEmailVerification(userCredential.user);
    saveLog({
      action: "register",
      user: userCredential.user.uid,
      data: {
        method: "email",
        userEmail: userCredential.user.email,
      },
    });
    return userCredential;
  } catch (error) {
    throw error;
  }
};

const loginUser = async (email, password) => {
  try {
    const result = await signInWithEmailAndPassword(auth, email, password);
    saveLog({
      action: "login",
      user: result.user.uid,
      data: {
        method: "email",
        userEmail: result.user.email,
      },
    });
    return result;
  } catch (error) {
    throw error;
  }
};

const logoutUser = () => {
  auth.signOut();
};

const loginWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  try {
    const result = await signInWithPopup(auth, provider);
    saveLog({
      action: "login",
      user: result.user.uid,
      data: {
        method: "google",
        userEmail: result.user.email,
      },
    });
    return result;
  } catch (error) {
    throw error;
  }
};

const subscribeToAuthChanges = (handleAuthChanges) => {
  onAuthStateChanged(auth, (user) => {
    handleAuthChanges(user);
  });
};

const resetPassword = async (email) => {
  try {
    return await sendPasswordResetEmail(auth, email);
  } catch (error) {
    throw error;
  }
};

const FirebaseAuthService = {
  getAuth,
  registerUser,
  loginUser,
  logoutUser,
  resetPassword,
  loginWithGoogle,
  subscribeToAuthChanges,
  sendEmailVerification,
};

export default FirebaseAuthService;
