import styled from "styled-components";

export const NewEventContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 3rem;
  max-width: 50rem;
  gap: 1rem;
  margin: 0 auto;
`;

export const FirstEventContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  padding: 5rem 3rem;
  max-width: 50rem;
  gap: 1rem;
  margin: 0 auto;
`;
