import styled from "styled-components";

export const ContainerToPreview = styled.div`
  width: 240px;
  max-width: 240px;
  min-width: 240px;
  height: 240px;
  max-height: 240px;
  min-height: 240px;
`;

export const Container = styled.div`
  padding: 3rem;
`;
