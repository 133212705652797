import Button from "components/Button";
import Loading from "components/Loading";
import EventList from "modules/events/components/List";
import SharedEventList from "modules/events/components/SharedEventList";
import { useNavigate } from "react-router-dom";
import { useGetEvents } from "services/events";
import { FormattedMessage } from "react-intl";
import { useContext } from "react";
import GlobalContext from "context";
import { NewEventContainer, FirstEventContainer } from "./styles";

const { StyledLayout, StyledHeader } = require("libs/styled-components/styles");

const Events = () => {
  const { user } = useContext(GlobalContext);
  const navigate = useNavigate();
  const { data, isLoading } = useGetEvents();
  const onNewEvent = () => {
    navigate(`/events/new`);
  };
  if (isLoading) {
    return <Loading />;
  }

  return (
    <StyledLayout>
      {data && data.length === 0 ? (
        <>
          <StyledHeader>
            <h2>
              <FormattedMessage
                id="title.greeting"
                values={{ name: user.name }}
              />
            </h2>
          </StyledHeader>
          <FirstEventContainer>
            <p>
              <FormattedMessage id="message.startOne" />
            </p>
            <p>
              <FormattedMessage id="message.startTwo" />
            </p>
            <Button onClick={onNewEvent}>
              <FormattedMessage id="button.start" />
            </Button>
          </FirstEventContainer>
        </>
      ) : (
        <>
          <StyledHeader>
            <h1>
              <FormattedMessage id="events.title" />
            </h1>
          </StyledHeader>
          <EventList events={data} />
          <NewEventContainer>
            <p>
              <FormattedMessage id="title.createAnotherEvent" />
            </p>
            <Button type="button" color="secondary" onClick={onNewEvent}>
              <FormattedMessage id="button.clickToCreate" />
            </Button>
          </NewEventContainer>
        </>
      )}
      <SharedEventList />
    </StyledLayout>
  );
};

export default Events;
