import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useGetPlans } from "services/plans";
import GlobalContext from "context";
import FirebaseFirestoreRestService from "libs/firebase/FirebaseFirestoreRestService";
import { useForm } from "react-hook-form";
import InputText from "components/InputText";
import { FormattedMessage } from "react-intl";
import { useCreateGiftCard } from "services/giftCards";
import Spinner from "components/Spinner";
import {
  ButtonContainer,
  Container,
  FormContainer,
  PriceTag,
  SummaryContainer,
} from "./styles";
import Loading from "components/Loading";
import { formatValue } from "utils";
import { useCookies } from "react-cookie";
import { getAffiliateByEmail } from "services/affiliates";
import { getPartnerByEmail } from "services/partners";
import { AES, enc } from "crypto-js";
import { Buffer } from "buffer";

const { StyledLine, StyledForm } = require("libs/styled-components/styles");

const Checkout = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { mutate: create, isLoading: isLoadingCreateGiftCard } =
    useCreateGiftCard();
  const { userMarket } = useContext(GlobalContext);
  const [isProcessing, setIsProcessing] = useState(false);
  const referral = useRef(null);
  const [cookies] = useCookies();

  const { data: plans, isLoading: isLoadingGetPlans } = useGetPlans();

  const plan = useMemo(() => {
    let planMarket;
    if (userMarket.toLowerCase() === "usd") {
      planMarket = "default_usd";
    } else if (userMarket.toLowerCase() === "eur") {
      planMarket = "default_eur";
    } else {
      planMarket = "default_brl";
    }
    return plans?.find((plan) => plan.type === planMarket);
  }, [plans, userMarket]);

  const onPayHandler = useCallback(
    async (data) => {
      setIsProcessing(true);

      // create a gift card passing buyer's email and name
      const newData = {
        buyerEmail: data.email,
        buyerName: data.name,
        refId: referral.current ? referral.current.id : null,
        refType: referral.current ? referral.current.type : null,
        refEmail: referral.current ? referral.current.email : null,
      };
      create(newData, {
        onSuccess: async (result) => {
          // create a payment passing the gift card id

          const payment = {
            price: plan?.stripePrice,
            giftCardId: result.id,
            customerEmail: result.buyerEmail,
            currency: userMarket.toLowerCase() || "brl",
            product: "gift-card",
          };
          try {
            const response =
              await FirebaseFirestoreRestService.createGiftCardPayment(payment);

            window.location.href = response.url;
          } catch (error) {
            setIsProcessing(false);

            console.error("onPayHandler error", error);
          }
        },
      });
    },
    [plan, create, userMarket]
  );

  useEffect(() => {
    const getRefCookie = async () => {
      if (cookies.cdt_ref) {
        const { email, type } = cookies.cdt_ref;
        let refUser = null;
        if (type === "affiliate") {
          const hexToBase64 = Buffer.from(email, "hex").toString("base64");
          const bytesEmail = AES.decrypt(
            hexToBase64,
            process.env.REACT_APP_AES_SECRET_KEY
          );
          const decryptEmail = bytesEmail.toString(enc.Utf8);

          refUser = await getAffiliateByEmail(decryptEmail);
          referral.current = { ...refUser, type };
        } else if (type === "partner") {
          refUser = await getPartnerByEmail(email);
          referral.current = { ...refUser, type };
        }
      }
    };
    getRefCookie();
  }, [cookies]);

  if (isLoadingGetPlans) return <Loading />;

  return (
    <Container>
      <SummaryContainer>
        <h2>
          <FormattedMessage
            id="title.giftCard"
            values={{
              code: (text) => <span>{text}</span>,
            }}
          />
        </h2>
        <p>
          <FormattedMessage id="description.giftCard" />
        </p>
        <PriceTag>{formatValue(userMarket, plan.value)}</PriceTag>
      </SummaryContainer>
      <FormContainer>
        <h3>
          <FormattedMessage id="title.yourData" />
        </h3>
        <StyledForm onSubmit={handleSubmit(onPayHandler)}>
          <StyledLine>
            <InputText
              label={<FormattedMessage id="label.name" />}
              field="name"
              errors={errors}
              required={true}
              register={register}
            />
          </StyledLine>
          <StyledLine>
            <InputText
              label={<FormattedMessage id="label.email" />}
              field="email"
              errors={errors}
              required={true}
              register={register}
            />
          </StyledLine>
          <ButtonContainer>
            <button type="submit" onContextMenu={(e) => e.preventDefault()}>
              {isProcessing || isLoadingCreateGiftCard ? (
                <Spinner height="2rem" />
              ) : (
                <FormattedMessage id="button.goToPayment" />
              )}
            </button>
          </ButtonContainer>
        </StyledForm>
        <p>
          <FormattedMessage id="message.giftCardPayment" />
        </p>
      </FormContainer>
    </Container>
  );
};

export default Checkout;
