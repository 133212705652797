import styled, { css } from "styled-components";

export const Control = styled.div`
  position: fixed;
  z-index: 500;
  top: auto;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  color: ${({ theme }) => theme.colors.text.light};
  background-color: ${({ theme }) => theme.colors.dark[900]};

  & > label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 1.4rem;
    overflow-wrap: break-word;
    text-align: center;
    gap: 2rem;
    & > button {
      cursor: pointer;
      padding: 1rem;
      width: 10rem;
      border: 1px solid ${({ theme }) => theme.colors.text.light};
      border-radius: 0.5rem;
      background-color: transparent;
      color: ${({ theme }) => theme.colors.text.light};
      font-size: 1.2rem;
    }
  }
  ${({ autoPlay }) =>
    autoPlay &&
    css`
      & > label > button {
        background-color: ${({ theme }) => theme.colors.text.light};
        color: ${({ theme }) => theme.colors.dark[900]};
      }
    `};
  @media (${({ theme }) => theme.medias.tablet}) {
    width: 40%;
    position: absolute;
    top: auto;
    bottom: 0;
    right: 0;
    left: auto;
  }
`;
