import { useForm } from "react-hook-form";
import { Container, ShowText } from "./styles";
import { useNavigate } from "react-router-dom";
import InputText from "components/InputText";
import { useContext, useState } from "react";
import { StyledForm, StyledLine } from "libs/styled-components/styles";
import LoginButton from "components/LoginButton";
import { useCreateUser } from "services/user";
import GlobalContext from "context";
import Spinner from "components/Spinner";
import { FormattedMessage } from "react-intl";
import { saveLog } from "services/logs";

const CompleteForm = ({ authUser }) => {
  const { userHandler } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);

  const { mutate } = useCreateUser();

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      await saveLog({
        action: "register",
        user: authUser.uid,
        data: {
          method: "google",
          userEmail: authUser.email,
        },
      });
    } catch (error) {
      console.log("savelog.error", error.message);
    }
    try {
      const newData = {
        ...data,
        id: authUser.uid,
        name: data.name,
        email: authUser.email,
        phone: data.phone,
      };

      mutate(newData, {
        onSuccess: (result) => {
          setLoading(false);
          userHandler(result);
          navigate(`../events`);
        },
      });
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Container>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <StyledLine>
          <ShowText>
            <label>
              <FormattedMessage id="label.email" />
              <span>{authUser.email}</span>
            </label>
          </ShowText>
        </StyledLine>
        <StyledLine>
          <InputText
            label={<FormattedMessage id="label.name" />}
            field="name"
            errors={errors}
            required={true}
            register={register}
          />
        </StyledLine>
        <StyledLine>
          <InputText
            label={<FormattedMessage id="label.phone" />}
            field="phone"
            type="tel"
            errors={errors}
            required={true}
            register={register}
          />
        </StyledLine>

        {error && <p>{error}</p>}
        <LoginButton type="submit">
          {loading ? <Spinner /> : <FormattedMessage id="button.finish" />}
        </LoginButton>
      </StyledForm>
    </Container>
  );
};

export default CompleteForm;
