const LOG_URL = `https://us-central1-cdt-analytics.cloudfunctions.net/api/v1/logs`;

const getBrowser = () => {
  const browsers = {
    Chrome: /Chrome/,
    Firefox: /Firefox/,
    Safari: /Safari/,
    Edge: /Edg/,
    IE: /MSIE|Trident/,
    Opera: /Opera|OPR/,
  };
  const { userAgent } = window.navigator;
  const isMobile = /Mobile/.test(userAgent);
  const isTablet = /Tablet/.test(userAgent);
  const isAndroid = /Android/.test(userAgent);
  const isiOS = /iPhone|iPad|iPod/.test(userAgent);
  const isWindows = /Windows/.test(userAgent);
  const isMac = /Macintosh/.test(userAgent);
  const isLinux = /Linux/.test(userAgent);
  const browser = Object.keys(browsers).find((browser) =>
    browsers[browser].test(userAgent)
  );
  const device = isMobile ? "mobile" : isTablet ? "tablet" : "desktop";
  const os = isAndroid
    ? "android"
    : isiOS
    ? "ios"
    : isWindows
    ? "windows"
    : isMac
    ? "mac"
    : isLinux
    ? "linux"
    : "other";
  return {
    browser,
    device,
    os,
  };
};

const getLocation = async () => {
  try {
    const response = await fetch("https://get.geojs.io/v1/ip/geo.json");
    const data = await response.json();
    const { country_code, city, region } = data;
    return { country_code, city, region };
  } catch (error) {
    console.log("error getting user location", error);
  }
};

const getIp = async () => {
  try {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.log("error getting user location", error);
  }
};

const getUserInfo = async () => {
  try {
    const { country_code, city, region } = await getLocation();
    const { browser, device, os } = getBrowser();
    const ip = await getIp();
    return {
      country: country_code,
      device,
      browser,
      os,
      ip,
      city,
      state: region,
    };
  } catch (error) {
    console.log("error getting user location", error);
  }
};

export const saveLog = async (data) => {
  const userInfo = await getUserInfo();
  const logData = {
    project: `webapp.capsuladotempo.com`,
    user: data.user ? data.user : "anonymous",
    ...userInfo,
    ...data,
  };

  try {
    // run if in development
    if (process.env.NODE_ENV === "development") {
      console.log("saveLog", logData);
    } else {
      await fetch(LOG_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(logData),
      });
      return {
        success: true,
      };
    }
  } catch (error) {
    const errorMsg = `saveLog [${JSON.stringify(error)}]`;
    console.log(errorMsg);
  }
};
