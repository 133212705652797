import styled, { createGlobalStyle } from "styled-components";
import "@fontsource/roboto";
import "@fontsource/roboto/700.css";
import farray from "assets/fonts/farray.woff";
import hyldae from "assets/fonts/hyldae-script.ttf";
import cormorant from "assets/fonts/cormorantSC-light.ttf";
import merriweaterLight from "assets/fonts/Merriweather-Light.ttf";
import merriweatherRegular from "assets/fonts/Merriweather-Regular.ttf";
import merriweatherBold from "assets/fonts/Merriweather-Bold.ttf";
import merriweatherBlack from "assets/fonts/Merriweather-Black.ttf";
import montserratRegular from "assets/fonts/Montserrat-Regular.ttf";
import montserratMedium from "assets/fonts/Montserrat-Medium.ttf";
import montserratSemiBold from "assets/fonts/Montserrat-SemiBold.ttf";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Farray Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Farray Regular'), url(${farray}) format('woff');
  }
  @font-face {
    font-family: 'Hyldae Script';
    font-weight: 400;
    src: local('Hyldae Script'), url(${hyldae}) format('truetype');
  }
  @font-face {
    font-family: 'Cormorant Light';
    font-weight: 300;
    src: local('Cormorant Light'), url(${cormorant}) format('truetype');
  }
  @font-face {
    font-family: 'Merriweather Light';
    font-weight: 300;
    src: local('Merriweather Light'), url(${merriweaterLight}) format('truetype');
  }
  @font-face {
    font-family: 'Merriweather Regular';
    font-weight: 400;
    src: local('Merriweather Regular'), url(${merriweatherRegular}) format('truetype');
  }
  @font-face {
    font-family: 'Merriweather Bold';
    font-weight: 700;
    src: local('Merriweather Bold'), url(${merriweatherBold}) format('truetype');
  }
  @font-face {
    font-family: 'Merriweather Black';
    font-weight: 900;
    src: local('Merriweather Black'), url(${merriweatherBlack}) format('truetype');
  }
  @font-face {
    font-family: 'Montserrat Regular';
    font-weight: 400;
    src: local('Montserrat Regular'), url(${montserratRegular}) format('truetype');
  }
  @font-face {
    font-family: 'Montserrat Medium';
    font-weight: 500;
    src: local('Montserrat Medium'), url(${montserratMedium}) format('truetype');
  }
  @font-face {
    font-family: 'Montserrat SemiBold';
    font-weight: 600;
    src: local('Montserrat SemiBold'), url(${montserratSemiBold}) format('truetype');
  }
  
  *,
  *::after,
  *::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  html{
    font-size: 62.5%;
    overflow: hidden;
    height: -webkit-fill-available;
  }
  html, body{
    padding: 0;
    margin: 0;
    color: ${({ theme }) => theme.colors.text.dark};
    font-family: "Montserrat Regular", sans-serif;
    font-weight: 400;
    background-color: ${({ theme }) => theme.colors.light[100]};
  }
  button, input{
    font-family: "Montserrat Medium", sans-serif;
  }

  body{
    height: 100%;
    min-height: 100%;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
    height: -webkit-fill-available;
  }

  h1, h2, h3{
    font-weight: 600;
  }

  h1{
    font-family: 'Merriweather Bold', serif;
    font-size: 2.8rem;
  }


  h2{
    font-size: 2.2rem;
  }

  h3{
    font-size: 2rem;
  }
  p {
    font-size: 1.8rem;
  }
  form{
    width: 100%;
  }
  button{
    cursor: pointer;
  }

  #root, .App{
    height: 100%;
    min-height: -webkit-fill-available;
    height: -webkit-fill-available;
  }

  .App{
    
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 56px auto;
    max-width: 1280px;
    margin: 0 auto;
    background-color: ${({ theme }) => theme.colors.light.default};
    position: relative;
    min-height: -webkit-fill-available;
    height: -webkit-fill-available;
  }
`;

export default GlobalStyle;

export const StyledLayout = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 70px auto;
  height: 100%;
  padding-top: 2rem;

  @media (${({ theme }) => theme.medias.tablet}) {
    display: grid;
    height: 100%;
  }
`;

export const StyledFullLayout = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  height: 100%;
  padding-top: 2rem;
`;

export const StyledHeader = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;

  & > div {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  @media (${({ theme }) => theme.medias.tablet}) {
    padding: 1rem 4rem;
    & > h3 {
      font-family: "Merriweather Bold", serif;
      font-size: 2.8rem;
    }
  }
`;

export const StyledForm = styled.form`
  width: 100%;
  @media (${({ theme }) => theme.medias.tablet}) {
    align-items: center;
  }
`;

export const StyledLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  font-size: 1.6rem;
  &:last-child {
    margin-bottom: 0rem;
  }
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media (${({ theme }) => theme.medias.tablet}) {
    padding: 0 2rem;
  }
`;

export const StyledMessage = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 2rem;
  padding: 0 3rem;
  gap: 1rem;
  & > button {
    cursor: pointer;
    padding: 1rem 2rem;
    width: inherit;
    align-self: auto;
  }
  @media (${({ theme }) => theme.medias.tablet}) {
    font-size: 2.4rem;
  }
`;

export const StyledSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 2rem;
  @media (${({ theme }) => theme.medias.tablet}) {
    align-self: start;
    justify-self: flex-start;
  }
`;

export const StyledButtonArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  height: 4.8rem;
`;

export const StyledList = styled.div`
  & > ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    position: relative;
    overflow-y: auto;
    gap: 1rem;

    & > li {
      padding: 1rem;
      background-color: ${({ theme }) => theme.colors.success[200]};
      border-radius: 0.5rem;
      display: flex;
      justify-content: space-between;

      & header {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
      }

      & > span {
        font-size: 1.8rem;
        font-weight: 600;
        margin-right: 1rem;
      }
      & > label {
        font-size: 1.8rem;
      }
    }
  }
`;
