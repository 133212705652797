import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  border-radius: 0.5rem;
  width: 100%;
  height: 10rem;
  background-color: ${({ theme }) => theme.colors.dark[800]};
  color: ${({ theme }) => theme.colors.text.light};
  @media (${({ theme }) => theme.medias.tablet}) {
    width: 48%;
    height: 20rem;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  flex-grow: 1;
  & > label {
    position: absolute;
    height: 100%;
    width: 10rem;
    padding: 0 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 3rem;
    background-image: linear-gradient(
      to right,
      rgb(0 0 0 / 100%),
      90%,
      rgb(0 0 0 / 0%)
    );
    @media (${({ theme }) => theme.medias.tablet}) {
      width: 100%;
      height: 4rem;
      bottom: 0;
      font-size: 2rem;
    }
  }
  & > img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
  }
`;

export const Control = styled.div`
  width: 10rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (${({ theme }) => theme.medias.tablet}) {
    position: absolute;
    height: 100%;
    width: 100%;
    cursor: pointer;
    &:hover > svg {
      fill: ${({ theme }) => theme.colors.primary.default};
    }
  }
`;

export const NowPlaying = styled.div`
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  color: ${({ theme }) => theme.colors.text.light};
  font-size: 2rem;
`;

export const DateItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > p {
    padding: 3px 0;
    text-align: center;
    width: 100%;
    font-size: 1.4rem;
    letter-spacing: 0.4rem;
  }

  & > p:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.light.default};
  }
  @media (${({ theme }) => theme.medias.tablet}) {
    flex-direction: row;
    & > p {
      padding: 0 1rem;
    }

    & > p:not(:last-child) {
      border-bottom: none;
      border-right: 1px solid ${({ theme }) => theme.colors.light.default};
    }
  }
`;
