import Button from "components/Button";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { ButtonContainer, Container } from "./styles";

const CancelMessage = () => {
  const navigate = useNavigate();
  const goToCheckout = () => {
    navigate("../gift-card/checkout", { replace: true });
  };

  const goToLandingPage = () => {
    window.location.replace("https://capsuladotempo.com/padrinhos");
  };

  return (
    <Container>
      <h1>
        <FormattedMessage id="payment.canceled" />
      </h1>
      <p>
        <FormattedMessage id="message.payment.canceled.options" />
      </p>
      <ButtonContainer>
        <Button type="button" onClick={goToCheckout}>
          <FormattedMessage id="button.tryAgain" />
        </Button>
        <Button color="secondary" type="button" onClick={goToLandingPage}>
          <FormattedMessage id="button.goToHome" />
        </Button>
      </ButtonContainer>
    </Container>
  );
};
export default CancelMessage;
