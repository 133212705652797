import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 2rem;
  padding-bottom: 4rem;
  gap: 2rem;
`;

export const EditContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 2rem;
  @media (${({ theme }) => theme.medias.tablet}) {
    flex-direction: row;
  }
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.primary.default};
  border-radius: 0.5rem;
  padding: 1rem;
  margin-top: 1rem;
  & > p {
    background-color: ${({ theme }) => theme.colors.light[200]};
    padding: 1rem;
    border-radius: 0.5rem;
  }
`;
