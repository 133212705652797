import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 0 2rem;
  padding-bottom: 2rem;
  max-width: 100rem;

  & > h4 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 1rem;
    font-size: 1.8rem;
    text-align: center;
    background-color: ${({ theme }) => theme.colors.light[200]};
    padding: 1rem;
    border-radius: 0.5rem;
    margin-bottom: 2rem;

    & > svg {
      min-width: 3rem;
    }
  }
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.primary.default};
  border-radius: 0.5rem;
  padding: 1rem;
  margin-top: 1rem;
  & > p {
    background-color: ${({ theme }) => theme.colors.light[200]};
    padding: 1rem;
    border-radius: 0.5rem;
  }
`;

export const CustomInput = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 1rem;
  & > label {
    display: block;
    position: relative;
    padding-left: 3rem;
    margin-bottom: 2.2rem;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    & > input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    & > span {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      border: 2px solid ${({ theme }) => theme.colors.primary.default};
      border-radius: 0.5rem;
    }
    & > input:checked ~ span {
      background-color: ${({ theme }) => theme.colors.primary.default};
    }
    & > span:after {
      content: "";
      position: absolute;
      display: none;
    }
    & > input:checked ~ span:after {
      display: block;
    }
    & > span:after {
      left: 7px;
      top: 3px;
      width: 7px;
      height: 12px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
`;
