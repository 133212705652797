import styled from "styled-components";

export const ContainerToPrint = styled.div`
  width: 2480px;
  max-width: 2480px;
  min-width: 2480px;
  height: 3508px;
  max-height: 3508px;
  min-height: 3508px;
  font-size: 40px;
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  grid-template-rows: 1fr 1px 1fr;
`;

export const Container = styled.div`
  display: block;
  overflow: hidden;
  width: 0;
  max-width: 0;
  height: 0;
  max-height: 0;
`;

export const TopLeft = styled.div`
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  /* transform: rotate(90deg); */
  transform: rotate(180deg);
`;

export const TopRight = styled.div`
  grid-column: 3 / 4;
  grid-row: 1 / 2;
  /* transform: rotate(270deg); */
  transform: rotate(180deg);
`;

export const BottomLeft = styled.div`
  grid-column: 1 / 2;
  grid-row: 3 / 4;
  /* transform: rotate(90deg); */
`;

export const BottomRight = styled.div`
  grid-column: 3 / 4;
  grid-row: 3 / 4;
  /* transform: rotate(270deg); */
`;

export const HorizontalDivider = styled.div`
  width: 100%;
  grid-column: 1 / 4;
  grid-row: 2 / 3;
  border-top: 1px dashed ${(props) => props.theme.colors.dark[100]};
`;

export const VerticalDividerTop = styled.div`
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  height: 100%;
  border-left: 1px dashed ${(props) => props.theme.colors.dark[100]};
  transform: rotate(180deg);
`;

export const VerticalDividerBottom = styled.div`
  grid-column: 2 / 3;
  grid-row: 3 / 4;
  height: 100%;
  border-left: 1px dashed ${(props) => props.theme.colors.dark[100]};
`;
