import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
  height: 100%;
  gap: 0.5rem;
  & > h4 {
    font-size: 1.6rem;
  }
`;

export const VideoWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  height: 50rem;
  min-height: 50rem;
  max-height: 50rem;
  background-color: ${({ theme }) => theme.colors.dark[900]};
  & > div {
    width: 100%;
    max-width: 100%;
    height: 50rem;
    min-height: 50rem;
    max-height: 50rem;
    & > iframe {
      width: 100%;
      max-width: 100%;
      height: 50rem;
      min-height: 50rem;
      max-height: 50rem;
    }
  }
  @media (${({ theme }) => theme.medias.tablet}) {
    height: 100%;
    max-height: 100%;
    & > div {
      height: 100%;
      min-height: 100%;
      max-height: 100%;
      & > iframe {
        height: 100%;
        max-height: 100%;
        min-height: 100%;
      }
    }
  }
`;
