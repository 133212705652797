import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  height: 100%;
`;

export const VideoWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  height: 30rem;
  min-height: 30rem;
  max-height: 30rem;
  background-color: ${({ theme }) => theme.colors.dark[900]};
  & > div {
    width: 100%;
    max-width: 100%;
    height: 30rem;
    min-height: 30rem;
    max-height: 30rem;
    & > iframe {
      width: 100%;
      max-width: 100%;
      height: 30rem;
      min-height: 30rem;
      max-height: 30rem;
    }
  }
  @media (${({ theme }) => theme.medias.tablet}) {
    height: 100%;
    max-height: 100%;
    & > div {
      height: 100%;
      min-height: 100%;
      max-height: 100%;
      & > iframe {
        height: 100%;
        max-height: 100%;
        min-height: 100%;
      }
    }
  }
`;

export const VideoInfo = styled.div`
  min-height: 0;
  max-height: 10rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0;
  gap: 2rem;
  color: ${({ theme }) => theme.colors.text.light};
  @media (${({ theme }) => theme.medias.tablet}) {
    padding: 2rem 0;
  }
`;

export const Control = styled.div`
  position: fixed;
  z-index: 500;
  top: auto;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  color: ${({ theme }) => theme.colors.text.light};
  background-color: ${({ theme }) => theme.colors.dark[900]};

  & > label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 1.4rem;
    overflow-wrap: break-word;
    text-align: center;
    gap: 2rem;
    & > button {
      cursor: pointer;
      padding: 1rem;
      width: 10rem;
      border: 1px solid ${({ theme }) => theme.colors.text.light};
      border-radius: 0.5rem;
      background-color: transparent;
      color: ${({ theme }) => theme.colors.text.light};
      font-size: 1.2rem;
    }
  }
  ${({ autoPlay }) =>
    autoPlay &&
    css`
      & > label > button {
        background-color: ${({ theme }) => theme.colors.text.light};
        color: ${({ theme }) => theme.colors.dark[900]};
      }
    `};
  @media (${({ theme }) => theme.medias.tablet}) {
    width: 40%;
    position: absolute;
    top: auto;
    bottom: 0;
    right: 0;
    left: auto;
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  & > button {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.5rem;
    background-color: ${({ theme }) => theme.colors.primary.default};
  }

  & > button:hover {
    background-color: ${({ theme }) => theme.colors.danger[200]};
  }
`;

export const ShareContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 2rem;
  & > h3 {
    text-align: center;
    font-size: 1.6rem;
  }
  & > button {
    cursor: pointer;
    padding: 1rem;
    border: none;
    border-radius: 0.5rem;
    background-color: ${(p) =>
      p.isCopied ? p.theme.colors.primary.default : p.theme.colors.light[900]};
    color: ${(p) =>
      p.isCopied ? p.theme.colors.text.light : p.theme.colors.dark[900]};
  }
`;

export const DownloadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 2rem;
  & > h3 {
    text-align: center;
    font-size: 1.6rem;
  }
`;
