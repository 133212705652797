import styled from "styled-components";

export const MailForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
`;

export const MailLine = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 1.6rem;
  gap: 2rem;
  padding: 2rem;
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.primary.default};
  background-color: ${({ theme }) => theme.colors.light.default};
  color: ${({ theme }) => theme.colors.text.dark};
`;

export const MailList = styled.div`
  width: 100%;
  background-color: ${(p) => p.theme.colors.light[50]};
  color: ${(p) => p.theme.colors.text.dark};
  border-radius: 0.5rem;
  font-size: medium;
  & > h3 {
    padding: 1rem;
    font-size: large;
  }
  & > ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    position: relative;
    overflow-y: auto;
    gap: 1rem;
    & > li {
      padding: 1rem 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid ${(p) => p.theme.colors.light[200]};
      &:last-child {
        border-bottom: none;
      }
      & > div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0.5rem;
        & label {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          font-size: 2rem;
        }
        & > span {
          max-width: 30rem;
          font-size: 1.4rem;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
      & > button {
        cursor: pointer;
        padding: 0 1rem;
        border-radius: 0.5rem;
        border: 1px solid ${({ theme }) => theme.colors.primary.default};
        background-color: ${({ theme }) => theme.colors.primary.default};
        color: ${({ theme }) => theme.colors.light.default};
      }
    }
  }
`;

export const AddButton = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  padding: 1.2rem 2rem;
  border-radius: 0.5rem;
  border: 1px solid
    ${(p) =>
      p.disabled ? p.theme.colors.light[800] : p.theme.colors.primary.default};
  background-color: ${(p) =>
    p.disabled ? p.theme.colors.light[800] : p.theme.colors.primary.default};
  color: ${({ theme }) => theme.colors.light.default};
`;

export const MailButton = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.4rem;
  font-size: 2rem;
  padding: 1rem 2rem;
  font-family: "Montserrat Medium", sans-serif;
  margin-top: 2rem;
  border-radius: 0.5rem;
  background-color: ${(p) => p.theme.colors.light.default};
  border: 1px solid ${(p) => p.theme.colors.primary.default};
  color: ${(p) => p.theme.colors.primary.default};
  cursor: pointer;
`;
