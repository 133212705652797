import styled from "styled-components";

export const StepContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  height: 5rem;
  width: 100%;
  position: relative;
  margin-bottom: 3rem;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: ${(p) => p.theme.colors.primary.default};
    bottom: 1rem;
    left: 0;
    padding: 0 2rem;
  }
`;

export const StepItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
  z-index: 10;
  & > label {
    font-size: 1rem;
    text-align: center;
  }
`;

export const StepCircle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 1px solid ${(p) => p.theme.colors.primary.default};
  background-color: ${(p) =>
    p.active ? p.theme.colors.primary.default : p.theme.colors.light.default};
  color: ${(p) =>
    p.active ? p.theme.colors.text.light : p.theme.colors.text.dark};
`;
