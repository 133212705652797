import Logo from "components/Logo";
import { Container } from "modules/terms/components/TermsContainer/styles";

const Conditions = () => {
  return (
    <>
      <Logo color="#222" />
      <Container>
        <h2>Termos e Condições de Uso</h2>
        <p>
          O presente termo e condições de uso determina que todo e qualquer
          material entre fotos, vídeos e documentos, poderão ser utilizados em
          atividades de relacionadas aos trabalhos realizados pela Cápsula do
          Tempo Eventos Ltda. de Curitiba-PR, com sede na rua Laura Nunes
          Fernandes, 211, bairro Parque da Fonte, sejam essas destinadas à
          divulgação ao público em geral.
        </p>
        <p>
          O processo de coleta de vídeos, imagens e áudio é realizado utilizando
          o dispositivo dos convidados ou terceiros e a qualidade de gravação e
          conteúdo encontram-se fora do controle e responsabilidade da Cápsula
          do Tempo Eventos.
        </p>
        <p>
          As imagens, vídeos e áudios coletados pelo software Cápsula do Tempo
          Eventos são de responsabilidade do usuário criador do evento.
        </p>
        <p>
          Todo o material coletado pelo software Cápsula do Tempo Eventos e
          compartilhado com os convidados, está sujeito a ser utilizado para
          fins que não são controlados pela Cápsula do Tempo Eventos. A Cápsula
          do Tempo Eventos não se responsabiliza por qualquer uso indevido do
          material coletado.
        </p>

        <p>
          A atividade de coleta de materiais pelo software Cápsula do Tempo
          Eventos está livre de qualquer cobrança de taxa ou valor sobre
          direitos autorais ou de uso de imagens por parte do usuários criador
          do evento e seus convidados.
        </p>
        <p>
          Ao utilizar o software Cápsula do Tempo Eventos, você concorda com os
          termos e condições de uso. Caso não concorde com os termos e condições
          de uso, não utilize o software.
        </p>
        <p>
          Estes termos e condições são regidos e interpretados de acordo com as
          leis do Cápsula do Tempo Eventos e você se submete irrevogavelmente à
          jurisdição exclusiva dos tribunais naquele estado ou localidade.
        </p>

        <h2>Política de Privacidade</h2>
        <p>
          A sua privacidade é importante para nós. É política do Cápsula do
          Tempo Eventos respeitar a sua privacidade em relação a qualquer
          informação sua que possamos coletar no site Cápsula do Tempo Eventos,
          e outros sites que possuímos e operamos.
        </p>
        <p>
          Solicitamos informações pessoais apenas quando realmente precisamos
          delas para lhe fornecer um serviço. Fazemo-lo por meios justos e
          legais, com o seu conhecimento e consentimento. Também informamos por
          que estamos coletando e como será usado.
        </p>
        <p>
          Apenas retemos as informações coletadas pelo tempo necessário para
          fornecer o serviço solicitado. Quando armazenamos dados, protegemos
          dentro de meios comercialmente aceitáveis ​​para evitar perdas e
          roubos, bem como acesso, divulgação, cópia, uso ou modificação não
          autorizados.
        </p>
        <p>
          Não compartilhamos informações de identificação pessoal publicamente
          ou com terceiros, exceto quando exigido por lei.
        </p>
        <p>
          O nosso site pode ter links para sites externos que não são operados
          por nós. Esteja ciente de que não temos controle sobre o conteúdo e
          práticas desses sites e não podemos aceitar responsabilidade por suas
          respectivas políticas de privacidade.
        </p>
        <p>
          Você é livre para recusar a nossa solicitação de informações pessoais,
          entendendo que talvez não possamos fornecer alguns dos serviços
          desejados.
        </p>
        <p>
          O uso continuado de nosso site será considerado como aceitação de
          nossas práticas em torno de privacidade e informações pessoais. Se
          você tiver alguma dúvida sobre como lidamos com dados do usuário e
          informações pessoais, entre em contato conosco.
        </p>
        <h3>Compromisso do Usuário</h3>
        <p>
          O usuário se compromete a fazer uso adequado dos conteúdos e da
          informação que o Cápsula do Tempo Eventos oferece no site e com
          caráter enunciativo, mas não limitativo:
        </p>
        <p>
          A) Não se envolver em atividades que sejam ilegais ou contrárias à boa
          fé a à ordem pública;
        </p>
        <p>
          B) Não difundir propaganda ou conteúdo de natureza racista,
          xenofóbica, qualquer tipo de pornografia ilegal, de apologia ao
          terrorismo ou contra os direitos humanos;
        </p>
        <p>
          C) Não causar danos aos sistemas físicos (hardwares) e lógicos
          (softwares) do Cápsula do Tempo Eventos, de seus fornecedores ou
          terceiros, para introduzir ou disseminar vírus informáticos ou
          quaisquer outros sistemas de hardware ou software que sejam capazes de
          causar danos anteriormente mencionados.
        </p>
        <h3>Mais informações</h3>
        <p>
          Esperamos que esteja esclarecido e, como mencionado anteriormente, se
          houver algo que você não tem certeza se precisa ou não, geralmente é
          mais seguro deixar os cookies ativados, caso interaja com um dos
          recursos que você usa em nosso site.
        </p>
      </Container>
    </>
  );
};

export default Conditions;
