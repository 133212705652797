import Button from "components/Button";
import { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { ButtonContainer, Container, ImageContainer } from "./styles";

import { useGetGiftCardById } from "services/giftCards";
import { useLocation, useParams } from "react-router-dom";
import Loading from "components/Loading";
import Spinner from "components/Spinner";
import { saveLog } from "services/logs";
import GlobalContext from "context";
import GiftCardImage from "assets/images/gift-card-masked.jpg";

const SuccessMessage = () => {
  const { user, language } = useContext(GlobalContext);
  // eslint-disable-next-line no-unused-vars
  const [session, setSession] = useState({});
  const location = useLocation();
  const sessionId = location.search.replace("?session_id=", "");
  const params = useParams();
  const { data: giftCard, isLoading } = useGetGiftCardById(params.id);
  const [isExporting, setIsExporting] = useState(false);

  useEffect(() => {
    async function fetchSession() {
      try {
        const res = await fetch("/checkout-session?sessionId=" + sessionId);
        setSession(res);
      } catch (error) {
        console.log("fetchSession", error);
      }
    }
    fetchSession();
  }, [sessionId]);

  const exportAsImage = async () => {
    setIsExporting(true);
    const lang = language === "pt-BR" ? "pt" : "en";
    const res = await fetch(
      `https://us-central1-cdt-utilities.cloudfunctions.net/api/v1/gift-cards?code=${giftCard?.code}&language=${lang}`
    );
    const blob = await res.blob();

    downloadImage(URL.createObjectURL(blob), "gift-card.png");
  };

  const downloadImage = (blob, fileName) => {
    const fakeLink = window.document.createElement("a");
    fakeLink.style = "display:none;";
    fakeLink.download = fileName;

    fakeLink.href = blob;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
    saveLog({
      action: "download-giftcard",
      giftCard: giftCard?.id,
      user: user?.id,
      data: {
        giftCardCode: giftCard?.code,
        buyerEmail: giftCard?.buyerEmail,
        buyerName: giftCard?.buyerName,
        userEmail: user?.email,
        userName: user?.name,
      },
    });
    setIsExporting(false);
  };

  if (isLoading) {
    return <Loading />;
  }

  if (!giftCard.code) {
    return (
      <Container>
        <h1>
          <FormattedMessage id="title.giftCardNotFound" />
        </h1>

        <p>
          <FormattedMessage id="description.giftCardNotFound" />
        </p>
      </Container>
    );
  }

  if (giftCard.status === "used") {
    return (
      <Container>
        <h1>
          <FormattedMessage id="title.giftCardUsed" />
        </h1>

        <p>
          <FormattedMessage id="description.giftCardUsed" />
        </p>
      </Container>
    );
  }

  return (
    <Container>
      <h1>
        <FormattedMessage id="title.giftCard.ready" />
      </h1>

      <h3>
        <FormattedMessage
          id="title.giftCard"
          values={{
            code: (text) => <span>{text}</span>,
          }}
        />
      </h3>
      <ImageContainer>
        <img
          src={GiftCardImage}
          alt={
            <FormattedMessage
              id="title.giftCard"
              values={{
                code: (text) => <span>{text}</span>,
              }}
            />
          }
        />
      </ImageContainer>
      <p>
        <FormattedMessage id="description.giftCardReady" />
      </p>
      <ButtonContainer>
        <Button type="button" onClick={exportAsImage}>
          {isExporting ? (
            <Spinner />
          ) : (
            <FormattedMessage id="button.download" />
          )}
        </Button>
      </ButtonContainer>
      <p>
        <FormattedMessage id="message.giftCardReady" />
      </p>
    </Container>
  );
};
export default SuccessMessage;
