import { useMutation } from "@tanstack/react-query";
import FirebaseFirestoreService from "libs/firebase/FirebaseFirestoreService";
const COLLECTION = "partners";

export const getPartnerByEmail = async (email) => {
  try {
    const response = await FirebaseFirestoreService.readDocuments({
      collection: COLLECTION,
      queries: [
        {
          field: "email",
          condition: "==",
          value: email,
        },
      ],
    });

    if (response.docs.length === 0) return null;
    return response.docs[0].data();
  } catch (error) {
    const errorMsg = `getPartnerByEmail.readDocuments [${JSON.stringify(
      error
    )}]`;
    console.log(errorMsg);
  }
};

export const useUpdatePartner = (options) => {
  return useMutation(
    async (payload) => {
      try {
        const data = {
          ...payload,
          updatedAt: new Date().toISOString(),
        };
        await FirebaseFirestoreService.updateDocument(
          COLLECTION,
          payload.id,
          data
        );

        return data;
      } catch (error) {
        const errorMsg = `useUpdatePartner.updateDocument [${JSON.stringify(
          error
        )}]`;
        console.log(errorMsg);
      }
    },
    {
      onSuccess: (result) => {},
      ...options,
    }
  );
};
