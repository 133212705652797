import styled from "styled-components";

export const Container = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 60rem;
  margin: 0 auto;
  & > h1 {
    width: 100%;
    text-align: center;
    margin-bottom: 3rem;
  }
  & > p {
    width: 100%;
    text-align: center;
    margin: 2rem 0;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 2rem;
`;
