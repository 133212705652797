import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 60rem;
    margin: 0 auto;
    gap: 2rem;
    & > p {
      text-align: center;
    }
  }
`;

export const ButtonElement = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20rem;
  height: 5rem;
  min-height: 5rem;
  border: 1px solid
    ${(p) =>
      p.disabled ? p.theme.colors.dark[200] : p.theme.colors.primary.default};
  background-color: ${(p) =>
    p.disabled ? p.theme.colors.dark[100] : p.theme.colors.primary.default};
  color: ${(p) =>
    p.disabled ? p.theme.colors.primary.default : p.theme.colors.light.default};
  font-size: 2rem;
  font-family: "Montserrat Medium", sans-serif;
  border-radius: 0.5rem;
  cursor: pointer;
`;
