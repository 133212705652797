import { useQuery } from "@tanstack/react-query";
import FirebaseFirestoreService from "libs/firebase/FirebaseFirestoreService";
const BASE_URL = `${process.env.REACT_APP_CLOUD_FUNCTION_UTILITIES_API_URL}`;

const COLLECTION = "videos";

export const useGetVideos = (eventId, options) => {
  return useQuery(
    [COLLECTION, "useGetVideos", eventId],
    async () => {
      try {
        const response = await FirebaseFirestoreService.readDocuments({
          collection: COLLECTION,
          queries: [
            {
              field: "eventId",
              condition: "==",
              value: eventId,
            },
            {
              field: "status",
              condition: "==",
              value: "uploaded_to_vimeo",
            },
          ],
          orderByField: "createdAt",
          orderByDirection: "desc",
        });
        const videos = response.docs.map((doc) => {
          const id = doc.id;
          const data = doc.data();
          return {
            ...data,
            id,
          };
        });
        return videos;
      } catch (error) {
        const errorMsg = `useGetVideos [${JSON.stringify(error)}]`;
        console.log(errorMsg);
      }
    },
    {
      ...options,
    }
  );
};

export const downloadVideo = async (videoId, fileName, callback) => {
  const apiUrl = `${BASE_URL}/v1/videos/${videoId}`;
  try {
    const videoFile = await fetch(apiUrl);

    const blob = await videoFile.blob();
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
    callback();
  } catch (error) {
    const errorMsg = `downloadVideo [${JSON.stringify(error)}]`;
    console.log(errorMsg);
    callback();
  }
};
