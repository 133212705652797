import { ButtonWrapper } from "./styles";

const Button = ({ type, onClick, children, color, disabled }) => {
  return (
    <ButtonWrapper
      type={type}
      onClick={onClick}
      color={color}
      disabled={disabled}
    >
      {children}
    </ButtonWrapper>
  );
};

export default Button;
