import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 2rem;
  gap: 1rem;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem;
  & > img {
    object-fit: contain;
  }
`;

export const BorderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.primary.default};
  border-radius: 0.5rem;
  padding: 2rem;
  gap: 3rem;
  margin-top: 2rem;
  & > h2 {
    text-align: center;
    font-family: "Montserrat Medium", sans-serif;
  }

  & > p {
    text-align: center;
    font-size: 1.6rem;
    @media (${({ theme }) => theme.medias.tablet}) {
      padding: 0 3rem;
    }
  }
  & > div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    & > button,
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1.4rem;
      font-size: 2rem;
      padding: 1rem 2rem;
      font-family: "Montserrat Medium", sans-serif;
      border-radius: 0.5rem;

      cursor: pointer;
      text-decoration: none;
    }

    & > button {
      border: 1px solid ${({ theme }) => theme.colors.primary.default};
      background-color: ${({ theme }) => theme.colors.primary.default};
      color: ${({ theme }) => theme.colors.light.default};
    }

    & > a {
      border: 1px solid ${({ theme }) => theme.colors.primary.default};
      background-color: ${({ theme }) => theme.colors.light.default};
      color: ${({ theme }) => theme.colors.primary.default};
    }
    @media (${({ theme }) => theme.medias.tablet}) {
      flex-direction: row;
      gap: 5rem;
    }
  }
`;
