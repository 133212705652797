import Logo from "components/Logo";
import QRCode from "components/QRCode";
import { FormattedMessage } from "react-intl";
import { Container, Content, LogoContainer, QRCodeContainer } from "./styles";

const ModelThree = ({ code, title }) => {
  return (
    <Container>
      <h1>
        <FormattedMessage id="title.messageNewlyweds" />
      </h1>
      <Content>
        <div>
          <h2>
            <FormattedMessage id="description.qrCode" />
          </h2>
        </div>
        <QRCodeContainer>
          <QRCode code={code} size={800} logoSize={150} />
        </QRCodeContainer>
      </Content>
      <LogoContainer>
        <Logo color="#000" />
      </LogoContainer>
    </Container>
  );
};

export default ModelThree;
