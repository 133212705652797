import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 3em;

  & > h1 {
    text-align: center;
    font-size: 3em;
    font-family: "Hyldae Script";
    font-weight: 200;
    margin-bottom: 1em;
  }

  & > h2 {
    text-align: center;
    font-size: 2em;
    font-family: "Cormorant Light";
    margin-bottom: 1.5em;
  }
`;
export const Content = styled.div`
  width: 18em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 3em;
  & > svg {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
`;

export const LogoContainer = styled.div`
  font-size: 0.5em;
`;
