import styled from "styled-components";
import { loading } from "assets/animation";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  inset: 0;
`;

export const Loader = styled.div`
  color: ${({ theme }) => theme.colors.primary.default};
  font-size: 1rem;
  position: relative;
  transform: translateZ(0);
  animation-delay: -0.16s;

  top: -1rem;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  animation-fill-mode: both;
  animation: ${loading} 1.8s infinite ease-in-out;

  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 0;

    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    animation-fill-mode: both;
    animation: ${loading} 1.8s infinite ease-in-out;
  }

  &::before {
    left: -2rem;
    animation-delay: -0.32s;
  }

  &::after {
    left: 2rem;
  }
`;
