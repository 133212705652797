import Loading from "components/Loading";
import Logo from "components/Logo";
import Spinner from "components/Spinner";
import GlobalContext from "context";
import FirebaseFirestoreRestService from "libs/firebase/FirebaseFirestoreRestService";

import { useCallback, useContext, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useUpdateEvent } from "services/events";
import { saveLog } from "services/logs";
import { useUpdatePartner } from "services/partners";
import { useGetPlans } from "services/plans";
import { formatValue } from "utils";

const { Container, ButtonElement, PriceContainer } = require("./styles");

const PartnerPurchase = ({ event, referral }) => {
  const { user } = useContext(GlobalContext);
  const navigate = useNavigate();
  const { mutate: updatePartner, isLoading: isLoadingUpdatePartner } =
    useUpdatePartner();
  const { mutate: updateEvent, isLoading: isLoadingUpdateEvent } =
    useUpdateEvent();
  const { data: plans, isLoading: isLoadingGetPlans } = useGetPlans();

  const plan = useMemo(() => {
    return plans?.find((plan) => plan.type === "partner_brl");
  }, [plans]);

  const defaultPlan = useMemo(() => {
    return plans?.find((plan) => plan.type === "default_brl");
  }, [plans]);

  const onFreeEvent = useCallback(() => {
    const updateEventData = {
      ...event,
      refId: referral.id,
      refType: referral.type,
      refEmail: referral.email,
      payment: "free-partner",
      status: "published",
    };
    updateEvent(updateEventData, {
      onSuccess: () => {
        const updatePartnerData = {
          id: referral.id,
          freeEvents: referral.freeEvents - 1,
        };
        updatePartner(updatePartnerData, {
          onSuccess: () => {
            saveLog({
              action: "click-payment",
              event: event?.id,
              user: user?.id,
              data: {
                payment: "free-partner",
                refId: referral.id,
                refEmail: referral.email,
                refType: referral.type,
                planPrice: plan?.stripePrice,
                planValue: plan?.value,
                planType: plan?.type,
                eventTitle: event?.title,
                eventDate: event?.date,
                eventCode: event?.code,
                eventStatus: "published",
                userEmail: user?.email,
                userName: user?.name,
                userOwner: event?.userOwner,
                userOwnerEmail: event?.userOwnerEmail,
                userOwnerName: event?.userOwnerName,
              },
            });
            navigate(`/events/${event.id}`);
          },
        });
      },
    });
  }, [event, navigate, referral, updatePartner, updateEvent, plan, user]);

  const onPaidEvent = useCallback(
    (e) => {
      e.preventDefault();

      const updateEventData = {
        ...event,
        refId: referral.id,
        refType: referral.type,
        refEmail: referral.email,
      };
      updateEvent(updateEventData, {
        onSuccess: async () => {
          const payment = {
            price: plan?.stripePrice,
            eventId: event.id,
            customerEmail: user.email,
            currency: "brl",
            product: "event",
          };

          const logData = {
            action: "click-payment",
            event: event?.id,
            user: user?.id,
            data: {
              payment: "paid-partner",
              refId: referral.id,
              refEmail: referral.email,
              refType: referral.type,
              planPrice: plan?.stripePrice,
              planValue: plan?.value,
              planType: plan?.type,
              eventTitle: event?.title,
              eventDate: event?.date,
              eventCode: event?.code,
              eventStatus: event?.status,
              userEmail: user?.email,
              userName: user?.name,
              userOwner: event?.userOwner,
              userOwnerEmail: event?.userOwnerEmail,
              userOwnerName: event?.userOwnerName,
            },
          };

          try {
            const response = await FirebaseFirestoreRestService.createPayment({
              payment,
              logData,
            });

            window.location.href = response.url;
          } catch (error) {
            console.error("onPayHandler error", error);
          }
        },
      });
    },
    [event, referral, updateEvent, plan, user]
  );

  const isLoading = isLoadingUpdatePartner || isLoadingUpdateEvent;

  if (isLoadingGetPlans) return <Loading />;

  return referral.freeEvents && referral.freeEvents > 0 ? (
    <Container>
      <Logo color="#222" fontBase="2" />
      <h3>
        <FormattedMessage id="title.youAreLuck" />
      </h3>
      <p>
        <FormattedMessage
          id="description.youAreLuck"
          values={{
            code: (text) => <span>{text}</span>,
          }}
        />
      </p>
      <PriceContainer>
        <h5>
          <FormattedMessage id="label.from" />:{" "}
          <span>{formatValue("brl", defaultPlan?.value)}</span>
        </h5>
        <h2>
          <FormattedMessage id="label.for" />:{" "}
          <span>{formatValue("brl", 0)}</span>
        </h2>
      </PriceContainer>
      <ButtonElement type="button" onClick={onFreeEvent}>
        {isLoading ? (
          <Spinner width="20rem" />
        ) : (
          <FormattedMessage id="button.createFreeEvent" />
        )}
      </ButtonElement>
    </Container>
  ) : (
    <Container>
      <Logo color="#222" fontBase="2" />
      <h3>
        <FormattedMessage id="title.youAreLuck" />
      </h3>
      <p>
        <FormattedMessage
          id="description.youAreLuckDiscount"
          values={{
            code: (text) => <span>{text}</span>,
          }}
        />
      </p>
      <PriceContainer>
        <h5>
          <FormattedMessage id="label.from" />:{" "}
          <span>{formatValue("brl", defaultPlan?.value)}</span>
        </h5>
        <h2>
          <FormattedMessage id="label.for" />:{" "}
          <span>{formatValue("brl", plan?.value)}</span>
        </h2>
      </PriceContainer>
      <ButtonElement
        type="button"
        onContextMenu={(e) => e.preventDefault()}
        onClick={onPaidEvent}
        disabled={isLoading}
      >
        {isLoading ? (
          <Spinner width="19rem" />
        ) : (
          <FormattedMessage id="button.payWithDiscount" />
        )}
      </ButtonElement>
    </Container>
  );
};
export default PartnerPurchase;
