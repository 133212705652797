import { StyledSection } from "libs/styled-components/styles";
import { useCallback, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  PaidContainer,
  ImageContainer,
  DescriptionContainer,
  PublicContainer,
  WatchContainer,
} from "./styles";
import { Printer, Share, Link } from "assets/svg";
import Banner from "assets/images/banner-placeholder.png";
import { FormattedMessage, useIntl } from "react-intl";
import GlobalContext from "context";
import { saveLog } from "services/logs";
import { formatDate } from "utils";

const EventSection = ({ event }) => {
  const { user } = useContext(GlobalContext);
  const navigate = useNavigate();
  const onPrintQRCode = () => {
    navigate(`/open-event/print/${event.id}`);
  };

  const onWatchVideos = () => {
    navigate(`/open-event/watch/${event.id}`);
  };

  const recordLink = useMemo(() => {
    return `https://evento.capsuladotempo.com/?event=${event.code}`;
  }, [event]);

  const intl = useIntl();

  const onOpenLink = useCallback(() => {
    saveLog({
      action: "open-link",
      event: event?.id,
      user: user?.id,
      data: {
        component: "OpenEvent/EventSection",
        recordLink: recordLink,
        eventTitle: event?.title,
        eventDate: event?.date,
        eventCode: event?.code,
        eventStatus: event?.status,
        userEmail: user?.email,
        userName: user?.name,
        userOwner: event?.userOwner,
        userOwnerEmail: event?.userOwnerEmail,
        userOwnerName: event?.userOwnerName,
      },
    });
  }, [event, recordLink, user]);

  const onShare = useCallback(() => {
    const text = intl.formatMessage({ id: "message.shareLink" });
    if (navigator.share) {
      navigator
        .share({
          title: event?.title,
          url: recordLink,
          text: text,
        })
        .then(() => {
          saveLog({
            action: "share-event",
            event: event?.id,
            user: user?.id,
            data: {
              component: "OpenEvent/EventSection",
              recordLink: recordLink,
              eventTitle: event?.title,
              eventDate: event?.date,
              eventCode: event?.code,
              eventStatus: event?.status,
              userEmail: user?.email,
              userName: user?.name,
              userOwner: event?.userOwner,
              userOwnerEmail: event?.userOwnerEmail,
              userOwnerName: event?.userOwnerName,
            },
          });
          console.log("Thanks for sharing!");
        })
        .catch(console.error);
    } else {
      console.log("no-navigator");
    }
  }, [event, recordLink, intl, user]);

  return (
    <StyledSection>
      <Container>
        <PublicContainer>
          <ImageContainer>
            <img src={event.banner ? event.banner : Banner} alt={event.title} />
          </ImageContainer>
          <DescriptionContainer>
            <label>
              <FormattedMessage id="event.date" />:{" "}
              {formatDate(event.market, event.date)}
            </label>
            <label>
              <FormattedMessage id="event.code" />:<span>{event.code}</span>
            </label>
          </DescriptionContainer>
        </PublicContainer>
        {event.status === "open" && (
          <WatchContainer>
            <h2>
              <FormattedMessage id="message.videos.readyToWatch" />
            </h2>
            <button onClick={onWatchVideos}>
              <FormattedMessage id="videos.open" />
            </button>
          </WatchContainer>
        )}

        {event.status === "open" && (
          <PaidContainer>
            <h2>
              <FormattedMessage id="message.event.ready" />
            </h2>
            <p>
              <FormattedMessage id="message.event.ready.description" />
            </p>
            <div>
              <a
                href={recordLink}
                onClick={onOpenLink}
                target="_blank"
                rel="noreferrer"
              >
                <FormattedMessage id="event.link" />
                <Link
                  height="26px"
                  width="26px"
                  outline="#f76c6f"
                  color="transparent"
                />
              </a>
              {navigator.share && (
                <button type="button" onClick={onShare}>
                  <FormattedMessage id="button.share" />
                  <Share
                    height="26px"
                    width="26px"
                    outline="#fff"
                    color="transparent"
                  />
                </button>
              )}
              <button onClick={onPrintQRCode}>
                <FormattedMessage id="event.qrCode" />
                <Printer
                  height="26px"
                  width="26px"
                  outline="white"
                  color="transparent"
                />
              </button>
            </div>
          </PaidContainer>
        )}
      </Container>
    </StyledSection>
  );
};

export default EventSection;
