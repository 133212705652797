import { useMemo } from "react";
import { QRCodeSVG } from "qrcode.react";
import { QRCodeArea } from "./styles";

const QRCode = ({ code, size = 128, logoSize = 28 }) => {
  const qrCodeLink = useMemo(() => {
    return `https://evento.capsuladotempo.com/?event=${code}`;
  }, [code]);

  return (
    <QRCodeArea>
      <QRCodeSVG
        className="scaling-svg"
        value={qrCodeLink}
        size={size}
        bgColor={"transparent"}
        fgColor={"#000000"}
        level={"L"}
        includeMargin={false}
        // imageSettings={{
        //   src: "/favicon500-white.png",
        //   x: null,
        //   y: null,
        //   height: 80,
        //   width: 80,
        //   excavate: true,
        // }}
      />
    </QRCodeArea>
  );
};

export default QRCode;
