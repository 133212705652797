import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import GlobalContext from "context";
import FirebaseFirestoreService from "libs/firebase/FirebaseFirestoreService";
import { useContext } from "react";

const COLLECTION = "giftCards";

export const useGetGiftCardById = (giftCardId, options) => {
  return useQuery(
    [COLLECTION, "useGetGiftCardById", giftCardId],
    async () => {
      try {
        const response = await FirebaseFirestoreService.readDocument(
          COLLECTION,
          giftCardId
        );
        const id = response.id;
        const data = response.data();
        // return only if status is not draft
        if (data?.status === "draft") {
          return {};
        }
        return {
          ...data,
          id,
        };
      } catch (error) {
        const errorMsg = `useGetGiftCardById.readDocument [${JSON.stringify(
          error
        )}]`;
        console.log(errorMsg);
      }
    },
    {
      ...options,
    }
  );
};

export const getGiftCardByCode = async (code) => {
  try {
    const response = await FirebaseFirestoreService.readDocuments({
      collection: "giftCards",
      queries: [
        {
          field: "code",
          condition: "==",
          value: code,
        },
        {
          field: "status",
          condition: "==",
          value: "published",
        },
      ],
    });
    if (response.docs.length === 0) {
      return null;
    }
    const id = response.docs[0].id;
    const data = response.docs[0].data();
    return {
      ...data,
      id,
    };
  } catch (error) {
    const errorMsg = `onRedeemHandler.readDocuments [${JSON.stringify(error)}]`;
    console.log(errorMsg);
  }
};

export const useCreateGiftCard = (options) => {
  const queryClient = useQueryClient();
  const { userMarket, language } = useContext(GlobalContext);
  return useMutation(
    async (payload) => {
      try {
        const code = await generateRandomCode();

        const searchIndexes = [
          payload.buyerEmail ? payload.buyerEmail.toLowerCase() : null,
          payload.buyerName ? payload.buyerName.toLowerCase() : null,
          userMarket,
          code,
          payload.refId ? payload.refId : null,
          payload.refType ? payload.refType : null,
          payload.refEmail ? payload.refEmail.toLowerCase() : null,
        ];

        const data = {
          ...payload,
          market: userMarket,
          code,
          language,
          status: "draft",
          searchIndexes,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        };
        const response = await FirebaseFirestoreService.createDocument(
          COLLECTION,
          data
        );
        return { id: response.id, ...data };
      } catch (error) {
        const errorMsg = `useCreateGiftCards.createDocument [${JSON.stringify(
          error
        )}]`;
        console.log(errorMsg);
      }
    },
    {
      onSuccess: (result) => {
        queryClient.removeQueries([COLLECTION, "useGetGiftCards"], {
          exact: true,
        });
      },
      ...options,
    }
  );
};

export const useUpdateGiftCard = (options) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload) => {
      const searchIndexes = [
        payload.code,
        payload.buyerEmail.toLowerCase(),
        payload.buyerName.toLowerCase(),
        payload.market ? payload.market : null,
        payload.payment ? payload.payment : null,
        payload.id,
        payload.refId ? payload.refId : null,
        payload.refType ? payload.refType : null,
        payload.refEmail ? payload.refEmail.toLowerCase() : null,
      ];
      const data = {
        ...payload,
        searchIndexes,
        updatedAt: new Date().toISOString(),
      };
      try {
        await FirebaseFirestoreService.updateDocument(
          COLLECTION,
          payload.id,
          data
        );
        return data;
      } catch (error) {
        const errorMsg = `useUpdateGiftCard.updateDocument [${JSON.stringify(
          error
        )}]`;
        console.log(errorMsg);
      }
    },
    {
      onSuccess: (result) => {
        const queryKey = [COLLECTION, "useGetGiftCardById", result.id];
        queryClient.removeQueries([COLLECTION, "useGetGiftCards"], {
          exact: true,
        });
        queryClient.setQueryData(queryKey, (oldData) => ({
          ...oldData,
          ...result,
        }));
      },
      ...options,
    }
  );
};

const generateRandomCode = async () => {
  try {
    const randomCode = Math.floor(100000 + Math.random() * 900000).toString();
    const response = await FirebaseFirestoreService.readDocuments({
      collection: COLLECTION,
      queries: [
        {
          field: "code",
          condition: "==",
          value: randomCode,
        },
      ],
    });
    if (response.docs.length > 0) {
      return generateRandomCode();
    } else {
      return randomCode;
    }
  } catch (error) {
    const errorMsg = `generateRandomCode [${JSON.stringify(error)}]`;
    console.log(errorMsg);
  }
};
