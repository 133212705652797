import { Calendar } from "assets/svg";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";
import enUS from "date-fns/locale/en-US";
import { useIntl } from "react-intl";
import { Container } from "./styles";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { convertToDate } from "utils";

const DateInput = ({
  label,
  value,
  control,
  validate,
  errors,
  name,
  minDate,
  maxDate,
}) => {
  const intl = useIntl();
  registerLocale("pt-BR", ptBR);
  registerLocale("en-US", enUS);
  setDefaultLocale("pt-BR");

  return (
    <Container className={errors[name] ? "error" : ""}>
      <label>{label}</label>
      <div>
        <Controller
          name={name}
          defaultValue={value}
          control={control}
          rules={{ required: true, validate: { validate } }}
          render={({ field: { onChange, onBlur, value } }) => {
            const onChangeDate = (date) => {
              onChange(date);
            };
            return (
              <DatePicker
                selected={convertToDate(value)}
                startDate={new Date()}
                onChange={onChangeDate}
                onBlur={onBlur}
                dateFormat={intl.formatMessage({ id: "date.format" })}
                placeholderText={intl.formatMessage({ id: "date.placeholder" })}
                locale={intl.locale}
                minDate={convertToDate(minDate)}
                maxDate={convertToDate(maxDate)}
              />
            );
          }}
        />
        <Calendar height={24} width={24} outline="#222" color="white" />
      </div>
      <ErrorMessage
        errors={errors}
        name={name}
        render={(e) => (
          <p>
            {e.message
              ? e.message
              : intl.formatMessage({ id: "error.required" })}
          </p>
        )}
      />
    </Container>
  );
};

export default DateInput;
