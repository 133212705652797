import { ButtonUpload, Container, ButtonFirstUpload } from "./styles";
import { Edit, Image } from "assets/svg";

import { FormattedMessage } from "react-intl";

function FileInput({ onImageSelected, image }) {
  const handleOnChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = function (e) {
        onImageSelected(reader.result);
      };
    }
  };

  return (
    <Container>
      {image && <img src={image} alt="banner" />}
      {image ? (
        <ButtonUpload>
          <FormattedMessage id="button.update" />
          <Edit height="35px" width="35px" outline="#333" color="transparent" />
          <input
            type="file"
            accept="image/*"
            onChange={handleOnChange}
            style={{}}
          />
        </ButtonUpload>
      ) : (
        <ButtonFirstUpload>
          <span>
            <FormattedMessage id="button.addBanner" />
            <Image
              height="35px"
              width="35px"
              outline="#333"
              color="transparent"
            />
          </span>
          <input
            type="file"
            accept="image/*"
            onChange={handleOnChange}
            style={{}}
          />
        </ButtonFirstUpload>
      )}
    </Container>
  );
}

export default FileInput;
