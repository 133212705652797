import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 1rem;
  & > h3 {
  }
  & > p {
    text-align: center;
  }
  & > label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    font-size: 2rem;
    padding: 2rem 0;
    font-weight: 700;

    & > input {
      font-size: 2rem;
      padding: 1rem;
      width: 12rem;
      text-align: center;
    }
    & > span {
      text-align: center;
      color: ${(p) => p.theme.colors.danger[600]};
    }
  }
`;

export const ButtonElement = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20rem;
  height: 5rem;
  min-height: 5rem;
  border: 1px solid
    ${(p) =>
      p.disabled ? p.theme.colors.dark[200] : p.theme.colors.primary.default};
  background-color: ${(p) =>
    p.disabled ? p.theme.colors.dark[100] : p.theme.colors.primary.default};
  color: ${(p) =>
    p.disabled ? p.theme.colors.primary.default : p.theme.colors.light.default};
  font-size: 2rem;
  font-family: "Montserrat Medium", sans-serif;
  border-radius: 0.5rem;
  cursor: pointer;
`;
