import styled from "styled-components";

export const Container = styled.div``;

export const CropperContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 90%;
`;

export const ControlsContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 2rem;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;
