import { useForm } from "react-hook-form";
import { Container } from "./styles";
import FirebaseAuthService from "libs/firebase/FirebaseAuthService";
import { useLocation, useNavigate } from "react-router-dom";
import InputText from "components/InputText";
import { useState } from "react";
import { StyledForm, StyledLine } from "libs/styled-components/styles";
import LoginButton from "components/LoginButton";
import { GoogleIcon } from "assets/svg";
import { useCreateUser } from "services/user";
import Spinner from "components/Spinner";
import { FormattedMessage } from "react-intl";

const Form = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);

  const { mutate, isLoading } = useCreateUser();

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await FirebaseAuthService.registerUser(
        data.email,
        data.password,
        data.name
      );

      const newData = {
        id: response.user.uid,
        name: data.name,
        email: data.email,
        phone: data.phone,
      };

      mutate(newData, {
        onSuccess: (result) => {
          setLoading(false);
          navigate(`../login`);
        },
      });
    } catch (error) {
      setError(error.message);
    }
  };

  const handleLoginWithGoogle = async () => {
    try {
      await FirebaseAuthService.loginWithGoogle();
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleLogin = () => {
    navigate(`../login/${location.search}`);
  };

  return (
    <Container>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <StyledLine>
          <InputText
            label={<FormattedMessage id="label.name" />}
            field="name"
            errors={errors}
            required={true}
            register={register}
          />
        </StyledLine>
        <StyledLine>
          <InputText
            label={<FormattedMessage id="label.phone" />}
            field="phone"
            type="tel"
            errors={errors}
            required={true}
            register={register}
          />
        </StyledLine>
        <StyledLine>
          <InputText
            label={<FormattedMessage id="label.email" />}
            field="email"
            type="email"
            errors={errors}
            required={true}
            register={register}
          />
        </StyledLine>
        <StyledLine>
          <InputText
            label={<FormattedMessage id="label.password" />}
            field="password"
            type="password"
            errors={errors}
            required={true}
            register={register}
          />
        </StyledLine>
        {error && <p>{error}</p>}
        <LoginButton type="submit">
          {loading || isLoading ? (
            <Spinner />
          ) : (
            <FormattedMessage id="button.create" />
          )}
        </LoginButton>
      </StyledForm>
      <LoginButton type="button" model="google" onClick={handleLoginWithGoogle}>
        <GoogleIcon height="30px" width="30px" />
        <FormattedMessage id="button.createWithGoogle" />
        <span></span>
      </LoginButton>
      <h3>
        <FormattedMessage id="title.alreadyHaveAccount" />
      </h3>
      <LoginButton type="button" model="new" onClick={handleLogin}>
        <FormattedMessage id="button.access" />
      </LoginButton>
    </Container>
  );
};

export default Form;
