import { StyledHeader, StyledLayout } from "libs/styled-components/styles";
import ForgotPasswordForm from "modules/forgotPassword/components/Form";
import { FormattedMessage } from "react-intl";

const ForgotPassword = () => {
  return (
    <StyledLayout>
      <StyledHeader>
        <h1>
          <FormattedMessage id="title.forgotPassword" />
        </h1>
      </StyledHeader>
      <ForgotPasswordForm />
    </StyledLayout>
  );
};
export default ForgotPassword;
