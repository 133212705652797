import { useQuery } from "@tanstack/react-query";
import FirebaseFirestoreService from "libs/firebase/FirebaseFirestoreService";

const COLLECTION = "plans";

export const useGetPlans = (options) => {
  return useQuery(
    [COLLECTION, "useGetPlans"],
    async () => {
      try {
        const response = await FirebaseFirestoreService.readDocuments({
          collection: COLLECTION,
          queries: [],
        });
        const events = response.docs.map((doc) => {
          const id = doc.id;
          const data = doc.data();
          return {
            ...data,
            id,
          };
        });
        return events;
      } catch (error) {
        const errorMsg = `useGetPlans [${JSON.stringify(error)}]`;
        console.log(errorMsg);
      }
    },
    {
      ...options,
    }
  );
};
