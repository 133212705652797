import { Close, Plus } from "assets/svg";
import InputText from "components/InputText";
import Spinner from "components/Spinner";
import GlobalContext from "context";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useUpdateEvent } from "services/events";
import { MailForm, MailLine, MailList, MailButton, AddButton } from "./styles";

const { StyledSection } = require("libs/styled-components/styles");

const MailListSection = ({ event }) => {
  const { user } = useContext(GlobalContext);
  const { mutate: update, isLoading } = useUpdateEvent();
  const [showForm, setShowForm] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const newData = {
      ...event,
      accessList: event.accessList
        ? [...event.accessList, data.email]
        : [data.email],
      mailList: event.mailList
        ? [
            ...event.mailList,
            {
              name: data.name,
              email: data.email,
            },
          ]
        : [
            {
              name: data.name,
              email: data.email,
            },
          ],
    };
    update(newData, {
      onSuccess: (result) => {
        setValue("name", "");
        setValue("email", "");
      },
    });
  };

  const onRemove = (item) => {
    const newData = {
      ...event,
      accessList: event.accessList.filter((mail) => mail !== item.email),
      mailList: event.mailList.filter((mail) => mail.email !== item.email),
    };
    update(newData, {
      onSuccess: (result) => {},
    });
  };

  return (
    <StyledSection>
      <h2>
        <FormattedMessage id="title.whoReceivedVideos" />
      </h2>
      <MailList>
        <ul>
          <li>
            <div>
              <label>{user.name}</label>
              <span>{user.email}</span>
            </div>
          </li>
          {event.mailList &&
            event.mailList.map((mail) => {
              return (
                <li key={mail.email}>
                  <div>
                    <label>{mail.name}</label>
                    <span>{mail.email}</span>
                  </div>
                  <button onClick={() => onRemove(mail)}>
                    <Close
                      height="30px"
                      width="30px"
                      outline="white"
                      color="transparent"
                    />
                  </button>
                </li>
              );
            })}
        </ul>
      </MailList>
      {showForm ? (
        <MailForm onSubmit={handleSubmit(onSubmit)}>
          <MailLine>
            <InputText
              label={<FormattedMessage id="label.name" />}
              field="name"
              errors={errors}
              required={true}
              register={register}
              type="text"
            />
            <InputText
              label={<FormattedMessage id="label.email" />}
              field="email"
              errors={errors}
              required={true}
              register={register}
              type="email"
            />
            <AddButton type="submit" disabled={isLoading}>
              {isLoading ? <Spinner /> : <FormattedMessage id="button.add" />}
            </AddButton>
          </MailLine>
        </MailForm>
      ) : (
        <MailButton onClick={() => setShowForm(true)}>
          <Plus color="transparent" outline="#f76c6f" width="24" height="24" />{" "}
          <FormattedMessage id="button.addRecipient" />
        </MailButton>
      )}
    </StyledSection>
  );
};
export default MailListSection;
