import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 2rem;
  & > h3 {
  }
  & > p {
    text-align: center;
    & > span {
      text-align: center;
      font-family: "Montserrat Medium";
      color: ${(p) => p.theme.colors.primary.default};
    }
  }
`;

export const ButtonElement = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 30rem;
  padding: 0 3rem;
  height: 5rem;
  min-height: 5rem;
  border: 1px solid
    ${(p) =>
      p.disabled ? p.theme.colors.dark[200] : p.theme.colors.primary.default};
  background-color: ${(p) =>
    p.disabled ? p.theme.colors.dark[100] : p.theme.colors.primary.default};
  color: ${(p) =>
    p.disabled ? p.theme.colors.primary.default : p.theme.colors.light.default};
  font-size: 2rem;
  font-family: "Montserrat Medium", sans-serif;
  border-radius: 0.5rem;
  cursor: pointer;
`;

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  & > h5 {
    width: 100%;
    font-size: 1.4rem;
    text-align: right;
    & > span {
      text-decoration: line-through;
    }
  }
  & > h2 {
    width: 100%;
    font-size: 1.4rem;
    & > span {
      font-size: 2.8rem;
    }
  }
`;
