import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 2rem;
  @media (${({ theme }) => theme.medias.tablet}) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;
