import { useCallback, useContext, useMemo, useState } from "react";
import { Container, PlayerContainer, PlaylistContainer } from "./styles";
import VideoPlayer from "./components/VideoPlayer";
import Group from "./components/Group";
import { saveLog } from "services/logs";
import GlobalContext from "context";

const VideoWatcher = ({ videoList }) => {
  const { user } = useContext(GlobalContext);
  const [currentVideo, setCurrentVideo] = useState(null);

  // select a specific video to play
  const playVideo = useCallback(
    (video) => {
      setCurrentVideo(video);
      saveLog({
        action: "watch-video",
        video: video?.videoId,
        user: user?.id,
        data: {
          vimeoUrl: video?.vimeoUrl,
          userEmail: user?.email,
          userName: user?.name,
        },
      });
    },
    [user]
  );

  // playlist with videos that are released
  const releasedPlaylist = useMemo(() => {
    const playlist = [];
    videoList.forEach((group) => {
      const releaseDate = new Date(group.releaseDate);
      releaseDate.setHours(0, 0, 0, 0);
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      if (releaseDate <= today) {
        playlist.push(...group.videos);
      }
    });
    if (playlist.length > 0) {
      playVideo(playlist[0]);
    }
    return playlist;
  }, [videoList, playVideo]);

  // play next video in the playlist
  const playNextVideo = useCallback(() => {
    const currentVideoIndex = releasedPlaylist.findIndex((video) => {
      return video.videoId === currentVideo.videoId;
    });

    const nextVideoIndex = currentVideoIndex + 1;
    if (nextVideoIndex < releasedPlaylist.length) {
      const nextVideo = releasedPlaylist[nextVideoIndex];
      playVideo(nextVideo);
    }
  }, [releasedPlaylist, currentVideo, playVideo]);

  return (
    <Container>
      <PlayerContainer>
        {releasedPlaylist.length > 0 && (
          <VideoPlayer video={currentVideo} playNextVideo={playNextVideo} />
        )}
      </PlayerContainer>

      <PlaylistContainer>
        {videoList.map((group) => (
          <Group
            key={group.name}
            group={group}
            currentVideo={currentVideo}
            playVideo={playVideo}
          />
        ))}
      </PlaylistContainer>
    </Container>
  );
};
export default VideoWatcher;
