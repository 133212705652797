import { Information } from "assets/svg";
import Button from "components/Button";
import { StyledButtonArea } from "libs/styled-components/styles";
import BannerSection from "modules/events/components/BannerSection";
import { FormattedMessage } from "react-intl";
import StepMeter from "../StepMeter";
import UpdateWrapper from "../UpdateWrapper";
import { Container } from "./styles";

const WizardAddBanner = ({ step, event, nextStep }) => {
  return (
    <Container>
      <StepMeter step={step} />
      <UpdateWrapper eventId={event.id}>
        <BannerSection />
      </UpdateWrapper>
      <h4>
        <Information
          height="30"
          width="30"
          color="transparent"
          outline="#222"
        />
        <FormattedMessage id="message.addBannerLater" />
      </h4>
      <StyledButtonArea>
        <div />

        <Button type="button" color="primary" onClick={nextStep}>
          <FormattedMessage id="button.next" />
        </Button>
      </StyledButtonArea>
    </Container>
  );
};
export default WizardAddBanner;
