import styled from "styled-components";

export const ContainerToImage = styled.div`
  width: 1240px;
  max-width: 1240px;
  min-width: 1240px;
  height: 1754px;
  max-height: 1754px;
  min-height: 1754px;
  font-size: 40px;
`;

export const Container = styled.div`
  display: none;
`;
