import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 0 2rem;
  padding-bottom: 2rem;
  max-width: 100rem;

  & > h4 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 1rem;
    font-size: 1.8rem;
    text-align: center;
    background-color: ${({ theme }) => theme.colors.light[200]};
    padding: 1rem;
    border-radius: 0.5rem;
    margin-bottom: 2rem;

    & > svg {
      min-width: 3rem;
    }
  }
`;
